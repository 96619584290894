import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useCurrentOrderContext } from "./CurrentOrderContext";
import { useFormValidationContext } from "./FormValidationContext";

const Context = createContext({});

export const useRenewalFunnel = () => useContext(Context);

export const RenewalFunnelContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const { orderId } = useParams();
  const [order, setOrder] = useState();
  const [deviceRenewalInfos, setDeviceRenewalInfos] = useState([]);
  const { setErrors } = useFormValidationContext();

  const { order: currentOrder, updateOrder: updateCurrentOrder } = useCurrentOrderContext();
  const [isSingleAddress, setIsSingleAddress] = useState(currentOrder?.devices.length === 1);

  const reloadOrder = useCallback(async () => {
    try {
      const fetchedOrder = await user.api.getOrder(orderId);

      setOrder(fetchedOrder.data);
    } catch (err) {
      console.error(err);
      if (import.meta.env.NODE_ENV === "production") {
        addToast(t("Une erreur est survenue lors de la récupération de la commande"));
      }
    }
  }, [user?.api, orderId, t]);

  const updateRenewalType = useCallback(
    async (deviceId, renewalType) => {
      setDeviceRenewalInfos((prev) =>
        prev.map((device) => (device.id === deviceId ? { ...device, renewal_type: renewalType } : device)),
      );

      await user.api.modifyDevice(deviceId, { renewal_type: renewalType });
    },
    [user?.api],
  );

  const checkRenewalValidation = useCallback(async () => {
    const paymentsMethod = await user?.api.getPaymentMethods();

    const validators = [
      {
        name: "iban",
        message: t("Vous devez ajouter un IBAN pour confirmer votre option de fin de contrat"),
        validator: () => user?.company.files.find((file) => file.type === "IBAN"),
      },
      {
        name: "sepa",
        message: t("Vous devez ajouter un mandat SEPA pour confirmer votre option de fin de contrat"),
        validator: () => paymentsMethod.data[0],
      },
    ];

    const validationErrors = {};

    for (const validation of validators) {
      if (!validation.validator()) {
        validationErrors[validation.name] = validation.message;
      }
    }
    if (Object.keys(validationErrors).length === 2) {
      addToast(t("Vous devez ajouter un IBAN et un mandat SEPA pour confirmer votre option de fin de contrat"));
    } else if (Object.keys(validationErrors).length === 1) {
      const errorMessage = Object.values(validationErrors)[0];

      addToast(t(errorMessage));
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  }, [user?.api, user?.company.files, t]);

  const hasSomeRenewalType = useCallback(
    (renewalType) => {
      if (!deviceRenewalInfos) {
        return false;
      }

      return deviceRenewalInfos.some((device) => device.renewal_type === renewalType);
    },
    [deviceRenewalInfos],
  );

  const onValidateShipping = useCallback(async () => {
    const currentOrderAddress = user?.company.addresses.find((a) => a.id === currentOrder?.shipping_address_id);

    try {
      if (isSingleAddress && currentOrder?.devices.some((d) => d.shipping_address_id !== currentOrderAddress?.id)) {
        updateCurrentOrder({ shipping_address_id: currentOrderAddress.id });
      }

      user.api.trackEvent("SHIPPING_VALIDATED");
    } catch (err) {
      console.error(err);
      addToast(t(err.response.data.message));
    }
  }, [currentOrder, user, addToast, t, updateCurrentOrder, isSingleAddress]);

  const getBuybackDeviceValue = useCallback(
    (device) => {
      const { buyback_value: buybackValue } = device;

      if (buybackValue) return buybackValue;

      const calculatedBuybackValue = device.rent * order.contract_duration * (25 / 100);

      return calculatedBuybackValue;
    },
    [order],
  );

  useEffect(() => {
    if (orderId) {
      reloadOrder();
    }
  }, [orderId, reloadOrder]);

  useEffect(() => {
    if (order) {
      const renewalInfo = order.devices.map((device) => ({
        ...device,
        renewal_type: device.renewal_type || "RENEW",
      }));

      setDeviceRenewalInfos(renewalInfo);
    }
  }, [order]);

  const values = useMemo(
    () => ({
      order,
      deviceRenewalInfos,
      reloadOrder,
      checkRenewalValidation,
      updateRenewalType,
      hasSomeRenewalType,
      getBuybackDeviceValue,
      onValidateShipping,
      isSingleAddress,
      setIsSingleAddress,
    }),
    [
      order,
      deviceRenewalInfos,
      reloadOrder,
      checkRenewalValidation,
      updateRenewalType,
      hasSomeRenewalType,
      getBuybackDeviceValue,
      onValidateShipping,
      isSingleAddress,
    ],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
