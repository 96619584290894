import React, { createContext, useContext, useMemo, useState } from "react";

const Context = createContext({});

export const useModalContext = () => useContext(Context);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);

  const values = useMemo(
    () => ({
      modal,
      setModal,
    }),
    [modal, setModal],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
