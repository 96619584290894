import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  FileDropzone,
  HorizontalLayout,
  Icon,
  Link,
  Modal,
  Radio,
  Text,
  Tooltip,
  VerticalLayout,
} from "tarmac-v2";

import { useUserContext } from "src/contexts/UserContext";
import { downloadFileBlob, imgExtensions } from "src/tools/FilesHelpers";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import MainLoader from "src/components/MainLoader";
import filesNiceName from "../filesNiceName";

const SignatoryFilesModal = (props) => {
  const { onClose, order, isOrderMonitoring } = props;
  const { t } = useTranslation();
  const { user: currentUser, reloadUser } = useUserContext();
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({
    SIGNATORY_ID: null,
    SIGNATORY_ID_VERSO: null,
    SIGNING_AUTHORITY: null,
  });
  const [showFiles, setShowFiles] = useState(false);
  const modalConfig = {
    title: "Ajouter les pièces justificatives obligatoires",
    mainButton: (
      <Button fullWidth onClick={onClose}>
        {t("Valider")}
      </Button>
    ),
  };
  const signatory = currentUser?.company?.employees.find((e) => e.id === order?.signatory_id);

  const submitRole = useCallback(async () => {
    setIsLoading(true);
    const userData = {};
    const isLR = Boolean(signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE"));

    if (isLR) {
      userData.roles_del = ["LEGAL_REPRESENTATIVE"];
    } else {
      userData.roles_add = ["LEGAL_REPRESENTATIVE"];
    }
    await currentUser.api.modifyUser(signatory.id, userData);
    reloadUser();
    setIsLoading(false);
  }, [currentUser, signatory]);

  const downloadFile = async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, file.original_name || file.url);
  };

  const downloadModel = () => {
    let fileUrl;

    if (currentUser.company.country === "France") {
      fileUrl = "/documents/FLEET - Template - Pouvoir de signature.docx";
    } else {
      fileUrl = "/documents/FLEET - Template - Power of Attorney.docx";
    }

    window.open(fileUrl, "_self", "noopener noreferrer");
  };

  const deleteFile = async (file, fileType) => {
    await currentUser.api.deleteFile(file.id);
    setFiles((prevFiles) => ({ ...prevFiles, [fileType]: undefined }));
    reloadUser();
  };

  const submitFile = async (e, type) => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "TO_EXAMINE");
    formData.append("file_contents", e.target.files[0]);
    formData.append("user_id", signatory.id);
    const { data: newFile } = await currentUser.api.addFile(formData);

    setFiles((prevFiles) => ({ ...prevFiles, [type]: newFile }));
    reloadUser();
  };

  useEffect(() => {
    const idRecto = signatory?.files.find((file) => file.type === "SIGNATORY_ID");
    const idVerso = signatory?.files.find((file) => file.type === "SIGNATORY_ID_VERSO");
    const signingAuthority = signatory?.files.find((file) => file.type === "SIGNING_AUTHORITY");

    if (currentUser.company.country === "Germany") {
      setFiles({ SIGNING_AUTHORITY: signingAuthority });

      return;
    }

    const requiredFiles = {
      SIGNATORY_ID: idRecto,
      SIGNATORY_ID_VERSO: idVerso,
    };

    if (currentUser.company?.country.toLowerCase() !== "spain") {
      requiredFiles.SIGNING_AUTHORITY = signingAuthority;
    }

    setFiles(requiredFiles);
  }, [signatory, currentUser]);

  useEffect(() => {
    if (signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE")) {
      if (!files.SIGNATORY_ID || isOrderMonitoring) {
        setShowFiles(true);
      } else {
        setShowFiles(false);
      }
    } else if (!files.SIGNATORY_ID || !files.SIGNING_AUTHORITY || isOrderMonitoring) {
      setShowFiles(true);
    } else {
      setShowFiles(false);
    }
  }, [signatory?.id]);

  const countryRequiredLR = ["Spain"].includes(currentUser.company.country);

  const isLR = signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE") || countryRequiredLR;

  const signatoryName = `${signatory?.firstName} ${signatory?.lastName}`;

  return (
    <Modal closeModal={onClose} title={t(modalConfig.title)} primaryButton={modalConfig.mainButton}>
      <VerticalLayout gap="16" isScrollable={showFiles}>
        {countryRequiredLR ? (
          <Text>
            {t(
              "Le signataire du contrat doit être le représentant légal pour pouvoir valider le contrat. Veuillez confirmer votre identité en remplissant les documents nécessaires.",
            )}
          </Text>
        ) : (
          <>
            <Text>
              {t(
                "Merci de bien valider l'identité du signataire en nous fournissant les pièces justificatives nécessaires.",
              )}
            </Text>
            <HorizontalLayout justifyContent="space-between">
              <VerticalLayout gap={12}>
                <Text variant="action" size="S">
                  <Trans>{{ signatoryName }} est-il le représentant légal ?</Trans>
                </Text>
                <HorizontalLayout gap={12}>
                  <Radio label={t("Oui")} isSelected={isLR} onClick={submitRole} />
                  <Radio label={t("Non")} isSelected={!isLR} onClick={submitRole} />
                </HorizontalLayout>
              </VerticalLayout>
              <VerticalLayout justifyContent="center">
                <Icon
                  size="S"
                  icon={faInfoCircle}
                  onMouseEnter={() => setShouldShowTooltip(true)}
                  onMouseLeave={() => setShouldShowTooltip(false)}
                />

                {shouldShowTooltip && (
                  <Tooltip arrow="right" variant="accent">
                    {t(
                      "Le représentant légal d'une société est généralement la personne qui dirige la société (le dirigeant ou le mandataire social), et qui assume sa gestion au quotidien.",
                    )}
                  </Tooltip>
                )}
              </VerticalLayout>
            </HorizontalLayout>
          </>
        )}
        {isLoading && <MainLoader />}

        {showFiles && !isLoading && (
          <VerticalLayout gap={8}>
            {Object.keys(files).map((fileName, i) =>
              fileName === "SIGNING_AUTHORITY" && isLR ? null : (
                <FileDropzone
                  key={i}
                  selectFile={(e) => submitFile(e, fileName)}
                  isSmall
                  width="100%"
                  files={files[fileName]}
                  acceptedExtensions={imgExtensions.join(", ")}
                  onDownload={downloadFile}
                  onDelete={(file) => deleteFile(file, fileName)}
                  text={t(filesNiceName[fileName])}
                  label={t(filesNiceName[fileName])}
                />
              ),
            )}
            {!isLR && currentUser.company?.country !== "Spain" && (
              <Text muted size="xs">
                <Trans>
                  Un pouvoir de signature est nécessaire pour déléguer la signature du contrat de location.{" "}
                  <Link onClick={downloadModel} span="true">
                    Téléchargez ici
                  </Link>{" "}
                  le modèle et ajoutez-le une fois signé.
                </Trans>
              </Text>
            )}
          </VerticalLayout>
        )}
      </VerticalLayout>
    </Modal>
  );
};

export default SignatoryFilesModal;
