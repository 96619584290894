import * as Sentry from "@sentry/react";

if (import.meta.env.REACT_APP_SENTRY_DSN) {
  const sentryEnv = import.meta.env.REACT_APP_SENTRY_ENV || import.meta.env.NODE_ENV || "development";
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: sentryEnv,
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 0.3,
    tracePropagationTargets: [/^\//, /^https:\/\/api\.fleet\.co\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.2,
  });
}
