import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { faPen, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useUserContext } from "src/contexts/UserContext";
import StripeCardFormModal from "src/components/cockpit/renewal/StripeCardFormModal";
import StripeSEPAFormModal from "src/components/cockpit/renewal/StripeSEPAFormModal";
import { useLocation } from "react-router-dom";
import { useToastContext } from "src/contexts/ToastContext";
import { Button, Card, Icon, IconButton, Layout, Text } from "tarmac-v3";
import { COUNTRIES } from "src/common/i18n-consts";
import { MandateManagerContext, useMandateManagerContext } from "./MandateManagerContext";

const MandateManager = () => {
  const { user: currentUser, reloadUser } = useUserContext();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { addToast } = useToastContext();
  const [sepaModalOpen, setSepaModalOpen] = useState(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState();
  // for USA, we use the same API key as for EU
  const apiKey =
    currentUser.company.country === COUNTRIES.UNITED_KINGDOM
      ? import.meta.env.REACT_APP_STRIPE_API_KEY_UK
      : import.meta.env.REACT_APP_STRIPE_API_KEY_EU;

  const stripePromise = useMemo(() => loadStripe(apiKey), []);

  const fetchPaymentMethod = useCallback(async () => {
    const { data: paymentMethodsData } = await currentUser.api.getPaymentMethods();

    await reloadUser();

    const activePaymentMethod = paymentMethodsData.find((pm) => pm.is_active);

    setPaymentMethod(activePaymentMethod);
  }, [currentUser.api]);

  useEffect(() => {
    fetchPaymentMethod();
  }, [sepaModalOpen, cardModalOpen]);

  useEffect(() => {
    const urlQuery = new URLSearchParams(search);
    const successCode = urlQuery.get("success_code");

    const getInfoFromUrl = () => {
      switch (successCode) {
        case "1":
          reloadUser();
          addToast(t("Votre autorisation de prélèvements a bien été ajoutée"), "success");
          break;
        case "2":
          addToast(t("Votre autorisation de prélèvements a été abandonnée"));
          break;
        default:
          break;
      }
    };

    try {
      getInfoFromUrl();
    } catch (error) {
      console.error(error);
    }
  }, [search]);

  const contextValue = useMemo(
    () => ({
      paymentMethod,
      setPaymentMethod,
      sepaModalOpen,
      setSepaModalOpen,
      cardModalOpen,
      setCardModalOpen,
    }),
    [sepaModalOpen, cardModalOpen, paymentMethod],
  );

  return (
    <MandateManagerContext.Provider value={contextValue}>
      <Layout direction="column" fullWidth>
        <CountryMandateManager paymentMethod={paymentMethod} />
        <Elements stripe={stripePromise}>
          {currentUser.company.country === COUNTRIES.UNITED_STATES ? (
            <StripeCardFormModal open={cardModalOpen} onClose={() => setCardModalOpen(false)} />
          ) : (
            <StripeSEPAFormModal open={sepaModalOpen} onClose={() => setSepaModalOpen(false)} />
          )}
        </Elements>
      </Layout>
    </MandateManagerContext.Provider>
  );
};

const CountryMandateManager = ({ paymentMethod }) => {
  const { user: currentUser } = useUserContext();

  switch (currentUser.company.country) {
    case COUNTRIES.UNITED_KINGDOM:
      return <UKMandateManager paymentMethod={paymentMethod} />;
    case COUNTRIES.UNITED_STATES:
      return <USMandateManager paymentMethod={paymentMethod} />;
    default:
      return <DefaultMandateManager paymentMethod={paymentMethod} />;
  }
};

const DefaultMandateManager = ({ paymentMethod }) => {
  const { t } = useTranslation();
  const { setSepaModalOpen } = useMandateManagerContext();

  if (!paymentMethod) {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setSepaModalOpen(true)}
        label={t("Ajouter mandat SEPA")}
        startIcon={faPlus}
      />
    );
  }

  return (
    <Card backgroundColor="secondary" sx={{ p: 1 }}>
      <Layout direction="row" justifyContent="space-between" alignItems="start">
        <Layout direction="column">
          <Text variant="body2" bold color="primary">
            {paymentMethod?.name}
          </Text>
          <Text variant="body2" color="secondary">{`**** **** **** **** **** ${paymentMethod?.last4}`}</Text>
        </Layout>
        <IconButton icon={<Icon icon={faPen} />} onClick={() => setSepaModalOpen(true)} />
      </Layout>
    </Card>
  );
};

const UKMandateManager = ({ paymentMethod }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user: currentUser } = useUserContext();
  const createCheckoutSession = async () => {
    const session = await currentUser.api.createCheckoutSession(pathname);

    window.open(session.data.session, "_self");
  };

  if (!paymentMethod) {
    return (
      <Button
        variant="outlined"
        color="darkGreen"
        onClick={createCheckoutSession}
        label={t("Mise en place d'un Débit Direct")}
        startIcon={faPlus}
      />
    );
  }

  return (
    <Layout direction="column" fullWidth>
      <Card backgroundColor="secondary" sx={{ p: 1 }}>
        <Layout direction="row" justifyContent="space-between" alignItems="start">
          <Layout direction="column">
            <Text bold variant="body2" color="primary">
              {paymentMethod?.name}
            </Text>
            <Layout direction="row" gap={4}>
              <Layout direction="column">
                {t("Code guichet")}
                <br />
                {paymentMethod?.bacs_sort_code}
              </Layout>
              <Layout direction="column">
                {t("Numéro de compte")}
                <br />
                {`***${paymentMethod?.last4}`}
              </Layout>
            </Layout>
          </Layout>
          <IconButton icon={<Icon icon={faPen} />} onClick={createCheckoutSession} />
        </Layout>
      </Card>
    </Layout>
  );
};

const USMandateManager = ({ paymentMethod }) => {
  const { t } = useTranslation();
  const { setCardModalOpen } = useMandateManagerContext();

  if (!paymentMethod) {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setCardModalOpen(true)}
        label={t("Ajouter une carte bancaire")}
        startIcon={faPlus}
      />
    );
  }

  return (
    <Card backgroundColor="secondary" sx={{ p: 1 }}>
      <Layout direction="row" justifyContent="space-between" alignItems="start">
        <Layout direction="column">
          <Text bold variant="body2" color="primary">
            {paymentMethod?.name}
          </Text>
          <Text variant="body2" color="secondary">{`**** **** **** **** **** ${paymentMethod?.last4}`}</Text>
        </Layout>
        <IconButton icon={<Icon icon={faPen} />} onClick={() => setCardModalOpen(true)} />
      </Layout>
    </Card>
  );
};

export default MandateManager;
