import React from "react";

import { useUserContext } from "src/contexts/UserContext";

import useLocalStorage from "src/hooks/useLocalStorage";

import SpecialRoute from "src/components/routing/SpecialRoute";

const AuthRoute = (props) => {
  const { component: Component, ...rest } = props;
  const { user: currentUser } = useUserContext();
  const [, , clearAccessToken] = useLocalStorage("accessToken");

  const accessControl = () => {
    // No user : clear token and carry on
    if (!currentUser || !currentUser.isLoaded()) {
      clearAccessToken();

      return { isAuthorized: true };
    }

    if (currentUser.email && !currentUser.isCompanyOnboarded()) {
      // Otherwise : Log out and stay on page
      clearAccessToken();

      return { isAuthorized: false, redirectTo: `/register?email=${currentUser.email}` };
    }

    // User loaded : go to cockpit
    return { isAuthorized: false, redirectTo: "/" };
  };

  return <SpecialRoute {...rest} accessControl={accessControl} component={Component} />;
};

export default AuthRoute;
