const useDataLayer = () => {
  const trackViewProductPage = (product, order) => {
    // Create mock order if not initialized
    const currentOrder = order || { contract_duration: 36 };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(product.price) * currentOrder.contract_duration,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: parseFloat(product.price) * currentOrder.contract_duration,
            quantity: 1,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.name,
          },
        ],
      },
    });
  };

  const trackPurchase = (order) => {
    window.dataLayer = window.dataLayer || [];
    const items = order.devices.map((d, index) => ({
      item_id: d.product_id,
      item_name: d.name,
      price: d.rent * order.contract_duration,
      quantity: 1,
      item_brand: d.product.brand,
      item_category: d.category,
      item_variant: d.product.name,
      index,
    }));

    // Clear the previous ecommerce object.
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: order.id,
        currency: "EUR",
        value: order.rent * order.contract_duration,
        items,
      },
    });
  };

  const trackAddToCart = (product, order) => {
    window.dataLayer = window.dataLayer || [];
    // Clear the previous ecommerce object.
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(product.price) * order.contract_duration,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: parseFloat(product.price) * order.contract_duration,
            quantity: 1,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.name,
          },
        ],
      },
    });
  };

  const trackRemoveFromCart = (product, order) => {
    window.dataLayer = window.dataLayer || [];
    // Clear the previous ecommerce object.
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(product.price) * order.contract_duration,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: parseFloat(product.price) * order.contract_duration,
            quantity: 1,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.name,
          },
        ],
      },
    });
  };

  return {
    trackViewProductPage,
    trackPurchase,
    trackAddToCart,
    trackRemoveFromCart,
  };
};

export default useDataLayer;
