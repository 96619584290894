import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Button, Card, Heading, Layout, Separator, Text, TextField, useTheme } from "tarmac-v3";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useFormValidationContext } from "src/contexts/FormValidationContext";
import AuthService from "src/components/auth/auth-service";
import GoogleConnect from "src/components/common/GoogleConnect";
import useLocalStorage from "src/hooks/useLocalStorage";
import useDeviceType from "src/hooks/useDeviceType";
import LoginEmailSent from "./LoginEmailSent";

const LoginBase = (props) => {
  const { goToResetPassword } = props;
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { updateUser } = useUserContext();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const { setErrors, errors } = useFormValidationContext();
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";
  const theme = useTheme();

  const [accessToken, setAccessToken] = useLocalStorage("accessToken");

  const [inputLogin, setInputLogin] = useState({ email: "", password: "" });
  const [loginEmailSent, setLoginEmailSent] = useState(false);
  const [identifiantsError, setIdentifiantsError] = useState(false);

  const service = new AuthService();

  useEffect(() => {
    const hasMagicLink = new URLSearchParams(search).get("magicLink");
    const email = new URLSearchParams(search).get("email");

    // E-mail in params : send link
    if (email) {
      service.requestMagicLink(email).then(() => setLoginEmailSent(true));
      setLoginEmailSent(true);
      history.replace("/login");
    } else if (hasMagicLink) {
      setLoginEmailSent(true);
      history.replace("/login");
    }
  }, [search]);

  const redirectToRegister = (err) => {
    if (err.response?.status === 403) {
      history.push(`/register${inputLogin.email ? `?email=${encodeURIComponent(inputLogin.email)}` : ""}`);
      addToast(t(err.response.data.message || "Une erreur est survenue lors de la connexion"));
    } else {
      console.error(err);
      addToast(t(err.response.data.message || "Une erreur est survenue lors de la connexion"));
    }
  };

  const checkFields = () => {
    const validations = [
      {
        name: "missing_email",
        message: t("Veuillez entrer une adresse e-mail professionnelle"),
        validator: () => Boolean(inputLogin.email),
      },
      {
        name: "missing_password",
        message: t("Veuillez entrer un mot de passe"),
        validator: () => Boolean(inputLogin.password),
      },
    ];

    const validationErrors = {};

    for (const validation of validations) {
      if (!showPasswordInput && validation.name === "missing_password") {
        continue;
      } else if (!validation.validator()) {
        validationErrors[validation.name] = validation.message;
      }
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    setErrors({});
    setIdentifiantsError(false);
    if (checkFields()) {
      try {
        const user = await service.login(inputLogin.email, inputLogin.password);

        updateUser(user);
      } catch (err) {
        if (err?.response?.data?.type === "Identifiants incorrects") {
          setIdentifiantsError(err.response.data.message);
        } else {
          redirectToRegister(err);
        }
      }
    }
  };

  const handleSubmitMagicLink = async () => {
    setErrors({});
    if (checkFields()) {
      try {
        await service.requestMagicLink(inputLogin.email);

        setLoginEmailSent(true);
      } catch (err) {
        redirectToRegister(err);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputLogin({ ...inputLogin, [name]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (showPasswordInput) {
        handleSubmit();
      } else {
        handleSubmitMagicLink();
      }
    }
  };

  useEffect(() => {
    const urlQuery = new URLSearchParams(search);
    const token = urlQuery.get("token");
    const error = urlQuery.get("error");
    const success = urlQuery.get("success");

    if (token) {
      // Get Token from popup query, send to window opener & close popup
      try {
        window.opener.getInfoFromPopup(token);
      } catch (err) {
        console.error(err);
      } finally {
        window.close();
      }
    } else if (error) {
      try {
        window.opener.getInfoFromPopup(null, error);
      } catch (err) {
        console.error(err);
      } finally {
        window.close();
      }
    } else if (success && success === "true") {
      window.opener.location.href = "/launchpad";
      window.close();
    }
  });

  window.getInfoFromPopup = (token, error) => {
    // Get Token from Popup
    if (token) {
      setAccessToken(token);
    } else if (error) {
      switch (error) {
        case "AccessDenied":
          addToast(t("Vous devez être administrateur Google Workspace pour effecteur cette action"));
          break;
        case "Unauthorized":
          addToast(t("Veuillez valider la connexion avec votre compte Google"));
          break;
        default:
          addToast(t("Une erreur est survenue"));
          break;
      }
    }
  };

  const onConnectGoogle = () => {
    let baseUrl;

    if (import.meta.env.REACT_APP_PR_NUMBER) {
      baseUrl = `https://fleet-co-pr-${import.meta.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      baseUrl = import.meta.env.REACT_APP_APIURL;
    }

    window.open(
      `${baseUrl}/auth/google${accessToken ? `?accessToken=${accessToken}` : ""}`,
      "Continuer avec Google",
      "height=600,width=450",
    );
  };

  return loginEmailSent ? (
    <LoginEmailSent />
  ) : (
    <Card sx={{ width: isMobile ? "100%" : 450 }} spacing={isMobile ? 2 : 3}>
      <Layout direction="column" spacing={4}>
        <Layout direction="column" alignItems="center">
          <Trans>
            <Heading variant="h3" textAlign="center">
              Se connecter au <span style={{ color: theme.palette.green[950] }}>Cockpit</span>
            </Heading>
          </Trans>
        </Layout>

        <Layout direction="column" gap={2} alignItems="center" fullWidth>
          <GoogleConnect onConnect={onConnectGoogle} />
          <Layout direction="column" spacing={2} fullWidth>
            <Separator
              label={
                <Text variant="body1" color="secondary">
                  {t("Ou")}
                </Text>
              }
              orientation="horizontal"
            />
          </Layout>
          <Layout direction="column" gap={2} alignItems="center" fullWidth>
            <Layout direction="column" gap={1} alignItems="left" fullWidth>
              <TextField
                label={t("Adresse e-mail professionnelle")}
                fullWidth
                name="email"
                type="email"
                placeholder={t("Email")}
                indication="*"
                value={inputLogin.email}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              {errors?.missing_email && (
                <Text variant="body2" color="error" align="left">
                  {t(errors.missing_email)}
                </Text>
              )}
              {identifiantsError && (
                <Text variant="body2" color="error" align="left">
                  {t(identifiantsError)}
                </Text>
              )}
            </Layout>
            {showPasswordInput && (
              <Layout direction="column" gap={1} alignItems="left" fullWidth>
                <TextField
                  label={t("Mot de passe")}
                  name="password"
                  type="password"
                  fullWidth
                  placeholder={t("Mot de passe")}
                  indication="*"
                  value={inputLogin.password}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                {errors?.missing_password && (
                  <Text variant="body2" color="error" align="left">
                    {t(errors.missing_password)}
                  </Text>
                )}
                {identifiantsError && (
                  <Text variant="body2" color="error" align="left">
                    {t(identifiantsError)}
                  </Text>
                )}
              </Layout>
            )}
          </Layout>
        </Layout>
        <Layout>
          <Layout direction="column" spacing={2} alignItems="center" fullWidth>
            <Button
              label={t(showPasswordInput ? "Se connecter" : "Se connecter avec un e-mail")}
              variant="contained"
              color="darkGreen"
              fullWidth
              onClick={showPasswordInput ? handleSubmit : handleSubmitMagicLink}
            />
            <Button
              label={
                <Text bold variant="body2">
                  {t(showPasswordInput ? "Mot de passe oublié" : "Se connecter avec un mot de passe")}
                </Text>
              }
              variant="text"
              color="secondary"
              data-cy="primaryLink"
              onClick={showPasswordInput ? goToResetPassword : () => setShowPasswordInput(true)}
            />
          </Layout>
        </Layout>
      </Layout>
    </Card>
  );
};

export default LoginBase;
