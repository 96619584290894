import React, { useState } from "react";

import { FormValidationContextProvider } from "src/contexts/FormValidationContext";
import ResetPassword from "./sections/ResetPassword";
import LoginBase from "./sections/LoginBase";
import LoginWrapper from "./LoginWrapper";

const Login = (props) => {
  const { source, setSource } = props;

  const [resetPassword, setResetPassword] = useState(false);

  return (
    <LoginWrapper source={source} setSource={setSource}>
      <FormValidationContextProvider>
        {resetPassword ? (
          <ResetPassword backToLogin={() => setResetPassword(false)} />
        ) : (
          <LoginBase goToResetPassword={() => setResetPassword(true)} source={source} />
        )}
      </FormValidationContextProvider>
    </LoginWrapper>
  );
};

export default Login;
