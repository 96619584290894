import { useCallback, useState } from "react";
import {
  applyBasicFilters,
  applyContractNumberFilters,
  applyOrderNumberFilters,
  applyStatusFilters,
} from "src/tools/OrderHelpers";

export const initialFilters = {
  order_number: [], // "NONE", "FR2024000012", ...
  status: [], // "En préparation", "Décision à prendre", ...
  num_devices: [], // "1", "2", ...
  leaser: [], // "LEASER1", "LEASER2", ...
  contract_start_date: [], // "2022-01-01", "2022-01-02", ...
  contract_number: [], // "FR2024000012", "FR2024000013", ...
};

const useOrderFilters = () => {
  const [filters, setFilters] = useState(initialFilters);
  const [sortModel, setSortModel] = useState({ field: "order_number", direction: "desc" });

  const onChangeFilters = useCallback(
    (field, value) => {
      const newFilters = structuredClone({ ...filters });

      if (Array.isArray(value)) {
        newFilters[field] = value;
      } else if (newFilters[field].includes(value)) {
        newFilters[field].splice(newFilters[field].indexOf(value), 1);
      } else {
        newFilters[field].push(value);
      }

      setFilters(newFilters);
    },
    [filters],
  );

  const onChangeSortModel = useCallback((field, direction) => {
    setSortModel({
      field,
      direction,
    });
  }, []);

  const applyFilters = useCallback(
    (orders) => {
      let newOrdersFiltered = [...orders];

      for (const filterKey in filters) {
        switch (filterKey) {
          case "order_number":
            newOrdersFiltered = applyOrderNumberFilters(newOrdersFiltered, filters);
            break;
          case "contract_number":
            newOrdersFiltered = applyContractNumberFilters(newOrdersFiltered, filters);
            break;
          case "num_devices":
            newOrdersFiltered = applyBasicFilters(newOrdersFiltered, filters, "num_devices");
            break;
          case "leaser":
            newOrdersFiltered = applyBasicFilters(newOrdersFiltered, filters, "leaser");
            break;
          case "status":
            newOrdersFiltered = applyStatusFilters(newOrdersFiltered, filters);
            break;

          default:
            break;
        }
      }

      return newOrdersFiltered;
    },
    [filters],
  );

  const applySort = useCallback(
    (devices) => {
      const newOrdersFiltered = [...devices];

      switch (sortModel.field) {
        case "leaser":
        case "status": {
          if (sortModel.direction === "asc") {
            newOrdersFiltered.sort((a, b) => a[sortModel.field]?.localeCompare(b[sortModel.field]));
          }

          if (sortModel.direction === "desc") {
            newOrdersFiltered.sort((a, b) => b[sortModel.field]?.localeCompare(a[sortModel.field]));
          }
          break;
        }
        case "rent":
        case "num_devices": {
          if (sortModel.direction === "asc") {
            newOrdersFiltered.sort((a, b) => Number(a[sortModel.field]) - Number(b[sortModel.field]));
          }

          if (sortModel.direction === "desc") {
            newOrdersFiltered.sort((a, b) => Number(b[sortModel.field]) - Number(a[sortModel.field]));
          }
          break;
        }

        case "order_number": {
          if (sortModel.direction === "asc") {
            newOrdersFiltered.sort((a, b) => {
              if (a.order_number === null) return 1;

              if (b.order_number === null) return -1;

              return a.order_number.localeCompare(b.order_number);
            });
          }

          if (sortModel.direction === "desc") {
            newOrdersFiltered.sort((a, b) => {
              if (a.order_number === null) return -1;

              if (b.order_number === null) return 1;

              return b.order_number.localeCompare(a.order_number);
            });
          }
          break;
        }

        case "contract_number": {
          if (sortModel.direction === "asc") {
            newOrdersFiltered.sort((a, b) => {
              const orderNumberA = a.contract_number || a.request_number;
              const orderNumberB = b.contract_number || b.request_number;

              if (orderNumberA === null) return 1;

              if (orderNumberB === null) return -1;

              return orderNumberA.localeCompare(orderNumberB);
            });
          }

          if (sortModel.direction === "desc") {
            newOrdersFiltered.sort((a, b) => {
              const orderNumberA = a.contract_number || a.request_number;
              const orderNumberB = b.contract_number || b.request_number;

              if (orderNumberA === null) return -1;

              if (orderNumberB === null) return 1;

              return orderNumberB.localeCompare(orderNumberA);
            });
          }
          break;
        }

        case "contract_start_date": {
          if (sortModel.direction === "asc") {
            newOrdersFiltered.sort((a, b) => {
              const dateA = a.contract_start_date ? new Date(a.contract_start_date) : null;
              const dateB = b.contract_start_date ? new Date(b.contract_start_date) : null;

              // Perform the comparison
              return dateA - dateB;
            });
          }

          if (sortModel.direction === "desc") {
            newOrdersFiltered.sort((a, b) => {
              const dateA = a.contract_start_date ? new Date(a.contract_start_date) : null;
              const dateB = b.contract_start_date ? new Date(b.contract_start_date) : null;

              // Perform the comparison
              return dateB - dateA;
            });
          }
          break;
        }

        default:
          break;
      }

      return newOrdersFiltered;
    },
    [sortModel],
  );

  const resetFilters = useCallback(() => {
    setFilters(initialFilters);
  }, []);

  return {
    filters,
    onChangeFilters,
    sortModel,
    onChangeSortModel,
    applyFilters,
    applySort,
    resetFilters,
    setFilters,
  };
};

export default useOrderFilters;
