import {
  faChair,
  faComputerMouse,
  faCouch,
  faDesktopAlt,
  faDisplay,
  faDuck,
  faHeadphones,
  faKeyboard,
  faLaptop,
  faMobileScreenButton,
  faTablePicnic,
  faTabletScreenButton,
  faToiletPortable,
  faUsbDrive,
} from "@fortawesome/pro-regular-svg-icons";
import { faUsb } from "@fortawesome/free-brands-svg-icons";

const whichIconCategoryToShow = (device) => {
  switch (device.category) {
    case "COMPUTER":
      return faDesktopAlt;
    case "TABLET":
      return faTabletScreenButton;
    case "SMARTPHONE":
      return faMobileScreenButton;
    case "SCREEN":
      return faDisplay;
    case "HEADPHONES":
      return faHeadphones;
    case "KEYBOARD":
      return faKeyboard;
    case "MOUSE":
      return faComputerMouse;
    case "ADAPTER":
      return faUsb;
    case "OTHER":
      return faDuck;
    case "CHAIR":
      return faChair;
    case "DESK":
      return faTablePicnic;
    case "ACOUSTIC_CHAIR":
      return faCouch;
    case "PHONEBOOTH":
      return faToiletPortable;
    default:
      return faDisplay;
  }
};

const whichIconTypeToShow = (device) => {
  switch (device.type) {
    case "DEVICE":
      return faLaptop;
    case "ACCESSORY":
      return faUsbDrive;
    case "SEAT":
      return faChair;
    case "TABLE":
      return faTablePicnic;
    case "ACOUSTIC":
      return faToiletPortable;
    default:
      return faDisplay;
  }
};

export { whichIconCategoryToShow, whichIconTypeToShow };
