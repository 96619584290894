import { useCallback, useState } from "react";
import {
  applyMailFilters,
  applyNameFilters,
  applyStatusFilters,
  applyTagsFilters,
  applyUsedDevicesFilters,
} from "src/tools/UserHelpers";

export const initialFilters = {
  name: [], // "NONE", "John Doe", ...
  tags: [], // "Tag Client", ...
  mail: [], // "NONE", "JohDoe@gmail.com", ...
  used_devices: [], // "NONE", "1", ...
  status: [], // "NEW_ARRIVAL", "CURRENT", "NEXT_DEPARTURE"
};

const useEmployeeFilters = () => {
  const [filters, setFilters] = useState(structuredClone({ ...initialFilters }));
  const [sortModel, setSortModel] = useState({ field: "date", direction: "desc" });

  const onChangeFilters = useCallback(
    (field, value) => {
      const newFilters = structuredClone({ ...filters });

      if (Array.isArray(value)) {
        newFilters[field] = value;
      } else if (newFilters[field].includes(value)) {
        newFilters[field].splice(newFilters[field].indexOf(value), 1);
      } else {
        newFilters[field].push(value);
      }

      setFilters(newFilters);
    },
    [filters],
  );

  const onChangeSortModel = useCallback((field, direction) => {
    setSortModel({
      field,
      direction,
    });
  }, []);

  const applyFilters = useCallback(
    (employee) => {
      let newEmployeesFiltered = [...employee];

      for (const filterKey in filters) {
        switch (filterKey) {
          case "status":
            newEmployeesFiltered = applyStatusFilters(newEmployeesFiltered, filters);
            break;

          case "name":
            newEmployeesFiltered = applyNameFilters(newEmployeesFiltered, filters);
            break;

          case "tags":
            newEmployeesFiltered = applyTagsFilters(newEmployeesFiltered, filters);
            break;

          case "mail":
            newEmployeesFiltered = applyMailFilters(newEmployeesFiltered, filters);
            break;

          case "used_devices":
            newEmployeesFiltered = applyUsedDevicesFilters(newEmployeesFiltered, filters);
            break;
          default:
            break;
        }
      }

      return newEmployeesFiltered;
    },
    [filters],
  );

  const applySort = useCallback(
    (employees) => {
      const newEmployeesFiltered = [...employees];

      switch (sortModel.field) {
        case "name": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => a.firstName.localeCompare(b.firstName));
            newEmployeesFiltered.sort((a, b) => a.lastName.localeCompare(b.lastName));
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => b.firstName.localeCompare(a.firstName));
            newEmployeesFiltered.sort((a, b) => b.lastName.localeCompare(a.lastName));
          }
          break;
        }

        case "mail": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.email === null) return 1;

              if (b.email === null) return -1;

              return a.email.localeCompare(b.email);
            });
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.email === null) return -1;

              if (b.email === null) return 1;

              return b.email.localeCompare(a.email);
            });
          }
          break;
        }
        case "tags": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.tags.length === 0) return 1;

              if (b.tags.length === 0) return -1;

              const sortedTagsA = a.tags.sort((tag1, tag2) => tag1.label.localeCompare(tag2.label));
              const sortedTagsB = b.tags.sort((tag1, tag2) => tag1.label.localeCompare(tag2.label));

              const tagA = sortedTagsA[0].label;
              const tagB = sortedTagsB[0].label;

              return tagA.localeCompare(tagB);
            });
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.tags.length === 0) return -1;

              if (b.tags.length === 0) return 1;

              const sortedTagsA = a.tags.sort((tag1, tag2) => tag1.label.localeCompare(tag2.label));
              const sortedTagsB = b.tags.sort((tag1, tag2) => tag1.label.localeCompare(tag2.label));

              const tagA = sortedTagsA[0].label;
              const tagB = sortedTagsB[0].label;

              return tagB.localeCompare(tagA);
            });
          }

          break;
        }

        case "used_devices": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.used_devices.length === 0) return 1;

              if (b.used_devices.length === 0) return -1;

              const usedDevicesA = a.used_devices.length;
              const usedDevicesB = b.used_devices.length;

              return usedDevicesA - usedDevicesB;
            });
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => {
              if (a.used_devices.length === 0) return -1;

              if (b.used_devices.length === 0) return 1;

              const usedDevicesA = a.used_devices.length;
              const usedDevicesB = b.used_devices.length;

              return usedDevicesB - usedDevicesA;
            });
          }

          break;
        }

        case "arrival_date": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => {
              // Determine which date to use for device a
              const dateA = a.arrival_date ? new Date(a.arrival_date) : new Date(a.created_at);
              // Determine which date to use for device b
              const dateB = b.arrival_date ? new Date(b.arrival_date) : new Date(b.created_at);

              // Perform the comparison
              return dateA - dateB;
            });
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => {
              // Determine which date to use for device a
              const dateA = a.arrival_date ? new Date(a.arrival_date) : new Date(a.created_at);
              // Determine which date to use for device b
              const dateB = b.arrival_date ? new Date(b.arrival_date) : new Date(b.created_at);

              // Perform the comparison
              return dateB - dateA;
            });
          }
          break;
        }

        case "departure_date": {
          if (sortModel.direction === "asc") {
            newEmployeesFiltered.sort((a, b) => {
              // Determine which date to use for device a
              const dateA = a.departure_date ? new Date(a.departure_date) : null;

              const dateB = b.departure_date ? new Date(b.departure_date) : null;

              // Perform the comparison
              return dateA - dateB;
            });
          }

          if (sortModel.direction === "desc") {
            newEmployeesFiltered.sort((a, b) => {
              // Determine which date to use for device a
              const dateA = a.departure_date ? new Date(a.departure_date) : null;

              const dateB = b.departure_date ? new Date(b.departure_date) : null;

              // Perform the comparison
              return dateB - dateA;
            });
          }
          break;
        }

        default:
          break;
      }

      return newEmployeesFiltered;
    },
    [sortModel],
  );

  const resetFilters = useCallback(() => {
    setFilters(structuredClone({ ...initialFilters }));
  }, []);

  return {
    filters,
    onChangeFilters,
    sortModel,
    onChangeSortModel,
    applyFilters,
    applySort,
    resetFilters,
    setFilters,
  };
};

export default useEmployeeFilters;
