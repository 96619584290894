const groupDevicesByProduct = (devices) =>
  devices.reduce((acc, device) => {
    const currentPgId = device.product.product_group.id;
    const groupedDevices = { ...acc };

    if (!groupedDevices[currentPgId]) {
      groupedDevices[currentPgId] = [];
    }

    groupedDevices[currentPgId].push(device);

    return groupedDevices;
  }, {});

// eslint-disable-next-line max-len
const groupDevicesByAddress = (devices, defaultAddress) =>
  devices.reduce((acc, device) => {
    const groupedDevices = { ...acc };
    let currentAddressId = device.shipping_address_id;

    if (currentAddressId === null && defaultAddress) {
      currentAddressId = defaultAddress.id;
    }

    if (!groupedDevices[currentAddressId]) {
      groupedDevices[currentAddressId] = [];
    }

    groupedDevices[currentAddressId].push(device);

    return groupedDevices;
  }, {});

const deviceSortFn = (a, b) => {
  const statusSorting = ["SHIPPED", "PURCHASED", "WAITING", "DELIVERED", "BDL_SENT", "RECEIVED"];
  const usageStatusSorting = ["UNASSIGNED", "RUNNING", "SPARE", "OUT_OF_SERVICE"];
  const sourceSorting = ["FLEET", "EXTERNAL", "EX-FLEET"];

  if (a.status && b.status && statusSorting.indexOf(a.status) - statusSorting.indexOf(b.status) !== 0) {
    return statusSorting.indexOf(a.status) - statusSorting.indexOf(b.status);
  }

  if (
    a.usage_status &&
    b.usage_status &&
    usageStatusSorting.indexOf(a.usage_status) - usageStatusSorting.indexOf(b.usage_status) !== 0
  ) {
    return usageStatusSorting.indexOf(a.usage_status) - usageStatusSorting.indexOf(b.usage_status);
  }

  if (sourceSorting.indexOf(a.source) - sourceSorting.indexOf(b.source) !== 0) {
    return sourceSorting.indexOf(a.source) - sourceSorting.indexOf(b.source);
  }

  if (a.user && b.user) {
    if (a.user.firstName === null) {
      return 1;
    }

    if (b.user.firstName === null) {
      return -1;
    }

    return a.user.firstName.localeCompare(b.user.firstName);
  }

  if (a.delivery_date && b.delivery_date) {
    return a.delivery_date - b.delivery_date;
  }

  return null;
};

export { groupDevicesByAddress, groupDevicesByProduct, deviceSortFn };
