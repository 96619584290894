import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faCircleDashed,
  faDesktop,
  faLaptop,
  faMobileScreenButton,
  faTabletScreenButton,
  faTag,
  faUsbDrive,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

import {
  Button,
  DropdownFilter,
  DropdownFilterWithSearch,
  HorizontalLayout,
  HorizontalSeparator,
  Link,
  Modal,
  Text,
  VerticalLayout,
} from "tarmac-v2";

import { useUserContext } from "src/contexts/UserContext";

import styles from "./DevicesFiltersModal.module.scss";

const DevicesFiltersModal = (props) => {
  const { filters, updateFilters, onClose, onSave } = props;
  const { t } = useTranslation();
  const { user } = useUserContext();

  const initialFilters = {
    status: [],
    usage_status: [],
    assignation: [],
    source: [],
    renewal_type: [],
    category: [],
    type: [],
    tags: [],
    employees: [],
    date: [],
    mdmEnrollment: [],
    securityStatus: [],
  };

  const [tagsQuery, setTagsQuery] = useState("");
  const [employeesQuery, setEmployeesQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });

  useEffect(() => {
    if (filters) {
      setSelectedFilters({ ...filters });
    }
  }, []);

  const selectValue = useCallback(
    (fieldName, value) => {
      const newFilters = { ...selectedFilters };

      const filterIndex = newFilters[fieldName].findIndex((v) => v === value);

      if (filterIndex !== -1) {
        newFilters[fieldName].splice(filterIndex, 1);
      } else {
        newFilters[fieldName].push(value);
      }

      updateFilters(newFilters);
      setSelectedFilters(newFilters);
    },
    [selectedFilters, updateFilters],
  );

  const statusFilterOptions = useMemo(
    () => [
      {
        name: t("En livraison"),
        value: "SHIPPED",
        selected: selectedFilters.status.includes("SHIPPED"),
        onSelect: (value) => selectValue("status", value),
      },
      {
        name: t("En service"),
        value: "RUNNING",
        selected: selectedFilters.usage_status.includes("RUNNING"),
        onSelect: (value) => selectValue("usage_status", value),
      },
      {
        name: t("En maintenance"),
        value: "SAV",
        selected: selectedFilters.usage_status.includes("SAV"),
        onSelect: (value) => selectValue("usage_status", value),
      },
      {
        name: t("Hors-service"),
        value: "OUT_OF_SERVICE",
        selected: selectedFilters.usage_status.includes("OUT_OF_SERVICE"),
        onSelect: (value) => selectValue("usage_status", value),
      },
      {
        name: t("En réserve"),
        value: "SPARE",
        selected: selectedFilters.usage_status.includes("SPARE"),
        onSelect: (value) => selectValue("usage_status", value),
      },
      {
        name: t("Non attribué"),
        value: "UNASSIGNED",
        selected: selectedFilters.usage_status.includes("UNASSIGNED"),
        onSelect: (value) => selectValue("usage_status", value),
      },
      {
        name: t("Attribué"),
        value: "ASSIGNED",
        selected: selectedFilters.assignation.includes("ASSIGNED"),
        onSelect: (value) => selectValue("assignation", value),
      },
      {
        name: t("Location Fleet"),
        value: "FLEET",
        selected: selectedFilters.source.includes("FLEET"),
        onSelect: (value) => selectValue("source", value),
      },
      {
        name: t("Equipement externe"),
        value: "EXTERNAL",
        selected: selectedFilters.source.includes("EXTERNAL"),
        onSelect: (value) => selectValue("source", value),
      },
    ],
    [
      selectValue,
      selectedFilters.assignation,
      selectedFilters.source,
      selectedFilters.status,
      selectedFilters.usage_status,
      t,
    ],
  );

  const equipmentFilterOptions = useMemo(
    () => [
      {
        name: t("Ordinateur"),
        value: "COMPUTER",
        icon: faDesktop,
        selected: selectedFilters.category.includes("COMPUTER"),
        onSelect: (value) => selectValue("category", value),
      },
      {
        name: t("Tablette"),
        value: "TABLET",
        icon: faTabletScreenButton,
        selected: selectedFilters.category.includes("TABLET"),
        onSelect: (value) => selectValue("category", value),
      },
      {
        name: t("Téléphone"),
        value: "SMARTPHONE",
        icon: faMobileScreenButton,
        selected: selectedFilters.category.includes("SMARTPHONE"),
        onSelect: (value) => selectValue("category", value),
      },
      {
        name: t("Accessoires"),
        value: "ACCESSORY",
        icon: faUsbDrive,
        selected: selectedFilters.type.includes("ACCESSORY"),
        onSelect: (value) => selectValue("type", value),
      },
    ],
    [selectValue, t, selectedFilters.type, selectedFilters.category],
  );

  const dateFilterOptions = useMemo(
    () => [
      {
        name: t("Aujourd'hui"),
        value: "TODAY",
        selected: selectedFilters.date.includes("TODAY"),
        onSelect: (value) => selectValue("date", value),
      },
      {
        name: t("Il y a 1 semaine"),
        value: "WEEK",
        selected: selectedFilters.date.includes("WEEK"),
        onSelect: (value) => selectValue("date", value),
      },
      {
        name: t("Il y a 1 mois"),
        value: "MONTH",
        selected: selectedFilters.date.includes("MONTH"),
        onSelect: (value) => selectValue("date", value),
      },
      {
        name: t("Il y a 6 mois"),
        value: "SIX_MONTHS",
        selected: selectedFilters.date.includes("SIX_MONTHS"),
        onSelect: (value) => selectValue("date", value),
      },
    ],
    [selectValue, t, selectedFilters.date],
  );

  const renewalOptions = useMemo(
    () => [
      {
        name: t("Racheté"),
        value: "BUYBACK",
        selected: selectedFilters.renewal_type.includes("BUYBACK"),
        onSelect: (value) => selectValue("renewal_type", value),
      },
      {
        name: t("Perdu/Volé"),
        value: "LOST",
        selected: selectedFilters.renewal_type.includes("LOST"),
        onSelect: (value) => selectValue("renewal_type", value),
      },
      {
        name: t("Cassé"),
        value: "BROKEN",
        selected: selectedFilters.renewal_type.includes("BROKEN"),
        onSelect: (value) => selectValue("renewal_type", value),
      },
      {
        name: t("Renouvelé"),
        value: "RENEW",
        selected: selectedFilters.renewal_type.includes("RENEW"),
        onSelect: (value) => selectValue("renewal_type", value),
      },
      {
        name: t("Arrêté"),
        value: "STOP",
        selected: selectedFilters.renewal_type.includes("STOP"),
        onSelect: (value) => selectValue("renewal_type", value),
      },
    ],
    [selectValue, t, selectedFilters.renewal_type],
  );

  const filtersSum = selectedFilters
    ? Object.keys(selectedFilters).reduce((acc, f) => acc + selectedFilters[f].length, 0)
    : 0;

  const tagsOptions = useMemo(() => {
    const options = !tagsQuery
      ? [...user.company.device_tags]
      : user.company.device_tags.filter((tag) => tag.label?.toLowerCase().startsWith(tagsQuery.toLowerCase()));

    return options.map((t) => ({
      name: t.label,
      value: t.id,
      selected: selectedFilters.tags.includes(t.id),
      onSelect: (value) => selectValue("tags", value),
    }));
  }, [selectValue, selectedFilters.tags, user.company.device_tags, tagsQuery]);

  const employeesOptions = useMemo(() => {
    const options = !employeesQuery
      ? [...user.company.employees]
      : user.company.employees.filter((employee) =>
          employee.firstName?.toLowerCase().startsWith(employeesQuery.toLowerCase()),
        );

    return options.map((e) => ({
      name: `${e.firstName} ${e.lastName}`,
      value: e.id,
      selected: selectedFilters.employees.includes(e.id),
      onSelect: (value) => selectValue("employees", value),
    }));
  }, [selectValue, selectedFilters.employees, user.company.employees, employeesQuery]);

  const handleTagsQueryChange = (e) => {
    setTagsQuery(e.target.value);
  };

  const handleEmployeesQueryChange = (e) => {
    setEmployeesQuery(e.target.value);
  };

  const resetFilters = () => {
    setSelectedFilters({ ...initialFilters });
    updateFilters({ ...initialFilters });
  };

  return (
    <Modal
      closeModal={onClose}
      title={t("Filtrer par")}
      primaryButton={
        <Button fullWidth onClick={onSave}>
          {t("Terminer")}
        </Button>
      }
    >
      <VerticalLayout justifyContent="space-between" className={styles.filtersWrapper}>
        <VerticalLayout gap={8}>
          <DropdownFilter
            icon={faCircleDashed}
            label={t("Statut")}
            optionValues={statusFilterOptions}
            count={statusFilterOptions.filter((f) => f.selected).length}
          />
          <DropdownFilter
            icon={faLaptop}
            label={t("Équipement")}
            optionValues={equipmentFilterOptions}
            count={equipmentFilterOptions.filter((f) => f.selected).length}
          />
          <DropdownFilterWithSearch
            icon={faTag}
            label={t("Tags")}
            optionValues={tagsOptions}
            count={tagsOptions.filter((f) => f.selected).length}
            value={tagsQuery}
            onChange={handleTagsQueryChange}
          />
          <DropdownFilterWithSearch
            icon={faUser}
            label={t("Personne")}
            optionValues={employeesOptions}
            count={employeesOptions.filter((f) => f.selected).length}
            value={employeesQuery}
            onChange={handleEmployeesQueryChange}
          />
          <DropdownFilter
            icon={faLaptop}
            label={t("Date d'ajout")}
            optionValues={dateFilterOptions}
            count={dateFilterOptions.filter((f) => f.selected).length}
          />
          <DropdownFilter
            icon={faCircleDashed}
            label={t("Ancienne location Fleet")}
            optionValues={renewalOptions}
            count={renewalOptions.filter((f) => f.selected).length}
          />
        </VerticalLayout>
        <VerticalLayout gap={8}>
          <HorizontalSeparator />
          <HorizontalLayout justifyContent="space-between">
            <Text size="s" bold>
              {filtersSum === 0
                ? t("Aucun filtre sélectionné")
                : t(`{{ filtersNb }} filtre${filtersSum > 1 ? "s" : ""} sélectionné${filtersSum > 1 ? "s" : ""}`, {
                    filtersNb: filtersSum,
                  })}
            </Text>
            <Link onClick={resetFilters}>{t("Réinitialiser les filtres")}</Link>
          </HorizontalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default DevicesFiltersModal;
