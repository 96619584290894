import React, { createContext, useContext, useMemo, useState } from "react";

const Context = createContext({});

export const useFormValidationContext = () => useContext(Context);

export const FormValidationContextProvider = ({ children }) => {
  const [errors, setErrors] = useState({});

  const values = useMemo(
    () => ({
      errors,
      setErrors,
    }),
    [errors, setErrors],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
