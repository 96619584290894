import axios from "axios";

class ApiService {
  constructor() {
    let baseUrl = "";
    const userToken = window.localStorage.getItem("accessToken");

    // PR Number given : Query Preview URL
    if (import.meta.env.REACT_APP_PR_NUMBER) {
      baseUrl = `https://fleet-co-pr-${import.meta.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      baseUrl = import.meta.env.REACT_APP_APIURL;
    }

    baseUrl += "/";

    this.axios = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: userToken ? `Bearer ${userToken}` : null,
      },
    });
  }

  // /* ---------- PRODUCT GROUPS ---------- */

  getProductGroups = (endpointQuery) => this.axios.get(`/product_group${endpointQuery || ""}`);

  getProductGroup = (productGroupId, endpointQuery) =>
    this.axios.get(`/product_group/${productGroupId}${endpointQuery || ""}`);

  // /* ---------- CATALOG BANNER ---------- */

  getCatalogBanner = () => this.axios.get("/catalog_banner");

  // /* ---------- IBER INFORM ---------- */

  getSpanishCompanyInfos = (searchCompany) => this.axios.get(`/iberinform?query=${searchCompany}`);

  // /* ---------- OFFER ---------- */

  getOffer = (offerCode) => this.axios.get(`/offer/${offerCode}`);

  getOffers = () => this.axios.get("/offer");

  // /* ---------- MDM APPLICATIONS ---------- */

  getMdmCatalogApps = () => this.axios.get("/application_mdm");
}

export default ApiService;
