import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CatalogFilters from "src/components/cockpit/catalog/sections/category/blocks/CatalogFilters";
import { Button, Drawer, Layout, Link, Text } from "tarmac-v3";

const initialFilters = {
  search: "",
  category: "",
  brands: [],
  usages: [],
  screenSizes: [],
  memories: [],
  processors: [],
  graphics: [],
  storages: [],
  connectivities: [],
  qualities: [],
  keyboardLayouts: [],
  noiseCancellation: null,
  hasMicrophone: null,
  connectionTypes: [],
  compliances: [],
  refreshRates: [],
  responseTimes: [],
  screenStands: [],
  definitions: [],
  optimizedTo: [],
  types: [],
  seatsNumbers: [],
  heights: [],
  armrests: [],
  lumbarSupports: [],
  dimensions: [],
  hasStool: null,
  hasVideoPackage: null,
  hasPremiumTablet: null,
  hasScreen: null,
  hasPowerPlug: null,
  hasAdjustableHeight: null,
  hasCableManagement: null,
  hasWheels: null,
  deliveryMaxTime: null,
  priceRangeMin: null,
  priceRangeMax: null,
  weight: null,
};

const CatalogFiltersModal = (props) => {
  const { category, filters, updateFilters, onClose, openModal } = props;
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });

  useEffect(() => {
    if (filters) {
      setSelectedFilters({ ...filters });
    }
  }, []);

  const handleChangeFilters = useCallback(
    (field, value) => {
      const newFilters = { ...selectedFilters };

      switch (field) {
        case "deliveryMaxTime":
          newFilters.deliveryMaxTime = newFilters.deliveryMaxTime === parseInt(value, 10) ? null : parseInt(value, 10);
          break;

        case "priceRangeMin":
        case "priceRangeMax":
          newFilters[field] = value ? parseInt(value, 10) : null;
          break;

        case "weight":
          newFilters[field] = newFilters[field] === value ? null : value;
          break;

        case "search":
          newFilters[field] = value;
          break;

        case "category":
          newFilters.category = newFilters.category === value ? "" : value;
          break;

        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
          newFilters[field] = newFilters[field] === value ? null : value;
          break;

        default:
          newFilters[field] = [...newFilters[field]];

          if (newFilters[field].includes(value)) {
            newFilters[field].splice(newFilters[field].indexOf(value), 1);
          } else {
            newFilters[field].push(value);
          }

          break;
      }

      setSelectedFilters(newFilters);
      updateFilters(newFilters);
    },
    [selectedFilters, updateFilters],
  );

  const resetFilter = useCallback(
    (key) => {
      const newFilters = { ...filters };

      switch (key) {
        case "search":
        case "category":
          break;

        case "priceRangeMin":
        case "priceRangeMax":
        case "deliveryMaxTime":
        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
        case "weight":
          newFilters[key] = null;
          break;

        default:
          newFilters[key] = [];
          break;
      }

      setSelectedFilters(newFilters);
      updateFilters(newFilters);
    },
    [filters, updateFilters],
  );

  const resetFilters = useCallback(() => {
    const newFilters = { ...filters };

    for (const key in newFilters) {
      switch (key) {
        case "search":
        case "category":
          break;

        case "priceRangeMin":
        case "priceRangeMax":
        case "deliveryMaxTime":
        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
        case "weight":
          newFilters[key] = null;
          break;

        default:
          newFilters[key] = [];
          break;
      }
    }

    setSelectedFilters(newFilters);
    updateFilters(newFilters);
  }, [filters, updateFilters]);

  const filtersCount = useMemo(
    () =>
      Object.keys(selectedFilters).reduce((acc, filterKey) => {
        let newCount = acc;

        if (Array.isArray(selectedFilters[filterKey])) {
          newCount += selectedFilters[filterKey].length;
        } else if (typeof selectedFilters[filterKey] === "string" && filterKey !== "weight") {
          newCount += 0;
        } else {
          newCount += 1;
        }

        return newCount;
      }, 0),
    [selectedFilters],
  );

  const DrawerActions = useMemo(
    () => (
      <Layout direction="column" spacing={2} fullWidth>
        <Layout direction="row" justifyContent="space-between">
          <Text variant="caption" bold data-cy="filterCounter">
            {filtersCount === 0
              ? t("Aucun filtre sélectionné")
              : t(`{{ filtersNb }} filtre${filtersCount > 1 ? "s" : ""} sélectionné${filtersCount > 1 ? "s" : ""}`, {
                  filtersNb: filtersCount,
                })}
          </Text>
          <Link
            label={
              <Text variant="caption" bold color="success">
                {t("Réinitialiser les filtres")}
              </Text>
            }
            onClick={resetFilters}
          />
        </Layout>
        <Button variant="contained" color="primary" label={t("Terminer")} fullWidth onClick={onClose} />
      </Layout>
    ),
    [t, onClose],
  );

  return (
    <Drawer open={openModal} onClose={onClose} title={t("Filtrer")} anchor="right" Actions={DrawerActions}>
      <Layout direction="column" spacing={3} justifyContent="space-between" fullHeight>
        <Layout direction="column" isScrollable>
          <CatalogFilters
            category={category}
            filters={selectedFilters}
            onChangeFilters={handleChangeFilters}
            resetFilter={resetFilter}
          />
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default CatalogFiltersModal;
