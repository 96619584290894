import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  faComputerMouse,
  faDesktop,
  faDisplay,
  faDuck,
  faHeadphones,
  faKeyboard,
  faLaptop,
  faLaptopSlash,
  faMobileScreenButton,
  faTabletScreenButton,
  faUsbDrive,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

import {
  Button,
  DropdownFilter,
  DropdownFilterWithSearch,
  HorizontalLayout,
  HorizontalSeparator,
  Link,
  Modal,
  Text,
  VerticalLayout,
} from "tarmac-v2";

import { useUserContext } from "src/contexts/UserContext";

const EmployeesFiltersModal = (props) => {
  const { filters, updateFilters, onClose, onSave } = props;
  const { t } = useTranslation();
  const { user } = useUserContext();

  const [tagsQuery, setTagsQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({ category: [], tags: [] });

  useEffect(() => {
    if (filters) {
      setSelectedFilters({ ...filters });
    }
  }, []);

  const selectValue = useCallback(
    (fieldName, value) => {
      const newFilters = { ...selectedFilters };

      const filterIndex = newFilters[fieldName].findIndex((v) => v === value);

      if (filterIndex !== -1) {
        newFilters[fieldName].splice(filterIndex, 1);
      } else {
        newFilters[fieldName].push(value);
      }

      updateFilters(newFilters);
      setSelectedFilters(newFilters);
    },
    [selectedFilters],
  );

  const tagsOptions = useMemo(() => {
    const options = !tagsQuery
      ? [...user.company.user_tags]
      : user.company.user_tags.filter((tag) => tag.label?.toLowerCase().startsWith(tagsQuery.toLowerCase()));

    return options.map((t) => ({
      name: t.label,
      value: t.id,
      selected: selectedFilters.tags.includes(t.id),
      onSelect: (value) => selectValue("tags", value),
    }));
  }, [selectValue, selectedFilters.tags, user.company.user_tags, tagsQuery]);

  const equipmentFilterOptions = [
    {
      name: t("Ordinateur"),
      value: "COMPUTER",
      icon: faDesktop,
      selected: selectedFilters.category.includes("COMPUTER"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Tablette"),
      value: "TABLET",
      icon: faTabletScreenButton,
      selected: selectedFilters.category.includes("TABLET"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Téléphone"),
      value: "SMARTPHONE",
      icon: faMobileScreenButton,
      selected: selectedFilters.category.includes("SMARTPHONE"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Écran"),
      value: "SCREEN",
      icon: faDisplay,
      selected: selectedFilters.category.includes("SCREEN"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Casque Audio"),
      value: "HEADPHONES",
      icon: faHeadphones,
      selected: selectedFilters.category.includes("HEADPHONES"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Clavier"),
      value: "KEYBOARD",
      icon: faKeyboard,
      selected: selectedFilters.category.includes("KEYBOARD"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Souris"),
      value: "MOUSE",
      icon: faComputerMouse,
      selected: selectedFilters.category.includes("MOUSE"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Adaptateur"),
      value: "ADAPTER",
      icon: faUsbDrive,
      selected: selectedFilters.category.includes("ADAPTER"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Autre"),
      value: "OTHER",
      icon: faDuck,
      selected: selectedFilters.category.includes("OTHER"),
      onSelect: (v) => selectValue("category", v),
    },
    {
      name: t("Aucun équipement"),
      value: "NONE",
      icon: faLaptopSlash,
      selected: selectedFilters.category.includes("NONE"),
      onSelect: (v) => selectValue("category", v),
    },
  ];

  const filtersSum = () => {
    let sum = 0;

    if (selectedFilters) {
      for (const filter in selectedFilters) {
        sum += selectedFilters[filter].length;
      }
    }

    return sum;
  };

  const handleTagsQueryChange = (e) => {
    setTagsQuery(e.target.value);
  };

  const resetFilters = () => {
    setSelectedFilters({ category: [], tags: [] });
    updateFilters(null);
  };

  return (
    selectedFilters && (
      <Modal
        closeModal={onClose}
        title={t("Filtrer par")}
        onClose
        primaryButton={
          <Button fullWidth onClick={onSave}>
            {t("Terminer")}
          </Button>
        }
      >
        <VerticalLayout justifyContent="space-between" fullHeight>
          <VerticalLayout gap={8}>
            <DropdownFilter
              icon={faLaptop}
              label={t("Équipement")}
              optionValues={equipmentFilterOptions}
              count={equipmentFilterOptions.filter((f) => f.selected).length}
            />
            <DropdownFilterWithSearch
              icon={faUsers}
              label={t("Équipe")}
              optionValues={tagsOptions}
              value={tagsQuery}
              onChange={handleTagsQueryChange}
              count={tagsOptions.filter((f) => f.selected).length}
            />
          </VerticalLayout>
          <VerticalLayout>
            <HorizontalSeparator />
            <HorizontalLayout justifyContent="space-between" style={{ marginTop: 8 }}>
              <Text size="S" bold>
                {filtersSum() === 0
                  ? t("Aucun filtre sélectionné")
                  : t(
                      `{{ filtersNb }} filtre${filtersSum() > 1 ? "s" : ""} sélectionné${filtersSum() > 1 ? "s" : ""}`,
                      { filtersNb: filtersSum() },
                    )}
              </Text>
              <Link onClick={resetFilters}>{t("Réinitialiser les filtres")}</Link>
            </HorizontalLayout>
          </VerticalLayout>
        </VerticalLayout>
      </Modal>
    )
  );
};

export default EmployeesFiltersModal;
