import React, { useEffect, useState } from "react";
import RPI from "react-phone-input-2";
import { useUserContext } from "src/contexts/UserContext";
import "react-phone-input-2/lib/material.css";
import { theme } from "tarmac-v3";
import { useTranslation } from "react-i18next";
import { LANGUAGE_VARIATIONS, LANGUAGE_VARIATIONS_LIST } from "src/common/i18n-consts";

const countryToPhoneCountryMap = {
  France: LANGUAGE_VARIATIONS.FR,
  Germany: LANGUAGE_VARIATIONS.DE,
  Spain: LANGUAGE_VARIATIONS.ES,
  "United Kingdom": LANGUAGE_VARIATIONS.GB,
  "United States": LANGUAGE_VARIATIONS.US,
  Other: LANGUAGE_VARIATIONS.FR,
};

const PhoneInput = ({
  country,
  onChange,
  setIsPhoneValid,
  value,
  error,
  required,
  hideLabel,
  fontSize,
  ...otherProps
}) => {
  const [isValid, setIsValid] = useState(null);
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  const inputStyle = {
    width: "100%",
    height: "20px",
    borderColor: theme.palette.grey[500],
    fontFamily: "Poppins",
    fontSize: fontSize || "16px",
  };

  const labelStyle = { fontFamily: "Poppins" };

  const handleChange = (value, country, _, formattedValue) => {
    // Consider phone valid if at least 7 digits
    // See : https://codesandbox.io/s/react-bf3uh?file=/src/Validation.js
    // And : https://stackoverflow.com/a/1245990/750758
    // Do not over-engineer as it is too complex, brings false-positives and is easily bypassed
    setIsValid(formattedValue?.length > 6);
    onChange(value, country.countryCode);
    onChange(`+${value}`, country.countryCode);
  };

  useEffect(() => {
    if (setIsPhoneValid && isValid !== null) {
      setIsPhoneValid(isValid);
    }
  }, [isValid, value]);

  if (error) {
    inputStyle.borderColor = theme.palette.error.main;
    labelStyle.color = theme.palette.error.main;
  }

  const requiredText = required ? " *" : "";

  return (
    <RPI
      country={country || countryToPhoneCountryMap[currentUser?.company?.country] || LANGUAGE_VARIATIONS.FR}
      preferredCountries={LANGUAGE_VARIATIONS_LIST}
      inputStyle={inputStyle}
      buttonStyle={{ zIndex: 2 }}
      onChange={handleChange}
      inputProps={{ ...otherProps }}
      value={value || ""}
      enableLongNumbers
      defaultErrorMessage="error"
      specialLabel={hideLabel ? "" : <span style={labelStyle}>{t("Numéro de téléphone") + requiredText}</span>}
      isValid={!error}
    />
  );
};

export default PhoneInput;
