import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, Search, Text, VerticalLayout } from "tarmac-v2";

import OldDeviceClickableCard from "src/components/cockpit/blocks/listCards/OldDeviceClickableCard";
import EmployeeSelectableListCard from "src/components/cockpit/blocks/listCards/EmployeeSelectableListCard";
import { useUserContext } from "src/contexts/UserContext";
import { employeeMatches } from "src/tools/SearchFilters";

import styles from "./AssignAllModal.module.scss";

const AssignAllModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, selectedDevices, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const [owner, setOwner] = useState(null);
  const [employeesList, setEmployeesList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchFocus, setSearchFocus] = useState(false);

  const assignUser = async () => {
    for (const device of selectedDevices) {
      await currentUser.api.modifyDevice(device.id, {
        user_id: owner.id,
        usage_status: "RUNNING",
      });
    }
    onSave();
    onClose();
  };

  const selectEmployee = (employee) => {
    setOwner(employee);
    setSearchInput("");
  };

  useEffect(() => {
    let currentEmployees = [...currentUser.company.employees];

    if (searchInput !== "") {
      currentEmployees = currentEmployees.filter((d) => employeeMatches(d, searchInput));
    }
    setEmployeesList(currentEmployees);
  }, [searchInput]);

  return (
    <Modal
      closeModal={onClose}
      title={t("Attribuer à un employé")}
      primaryButton={
        <Button fullWidth onClick={assignUser}>
          {t("Enregistrer")}
        </Button>
      }
    >
      <VerticalLayout gap={32}>
        <VerticalLayout gap={8}>
          <div className={styles.inputEmployeesContainer}>
            <Search
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={t("Rechercher")}
              autoFocus={searchFocus}
              onBlur={() => !searchInput && setSearchFocus(false)}
              isOpen
            />
            {searchInput !== "" && (
              <VerticalLayout gap={8} className={styles.employeesListWrapper}>
                {employeesList.map((employee, i) => (
                  <EmployeeSelectableListCard
                    key={i}
                    employee={employee}
                    canHover
                    noCheckbox
                    onCardClick={() => selectEmployee(employee)}
                  />
                ))}
              </VerticalLayout>
            )}
          </div>
          {owner && <EmployeeSelectableListCard employee={owner} noCheckbox />}
        </VerticalLayout>
        <VerticalLayout gap={8} className={styles.attributionText}>
          <Text>
            {owner
              ? t("Les équipements suivants seront attribués à {{ ownerName }}", {
                  ownerName: `${owner.firstName} ${owner.lastName}`,
                })
              : t("Les équipements suivants seront attribués à l'employé sélectionné")}
          </Text>
          {selectedDevices.map((device, index) => (
            <OldDeviceClickableCard
              userToShow={`${device.user?.firstName || ""} ${device.user?.lastName || ""}`}
              key={`devices-list-option-${index}`}
              data-cy={`devices-list-option-${index}`}
              device={device}
              noCheckbox
              canHover
              displayAssignee
            />
          ))}
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default AssignAllModal;
