import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Layout, Text, TextField } from "tarmac-v3";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import TagWithOptionalImages from "../../employeeV3/blocks/TagWithOptionalImages";
import TagSelectAttribution from "./TagSelectAttribution";

const TagsAttribution = (props) => {
  const {
    user,
    device,
    onChange,
    isChanging,
    toggleMenu,
    setTagInfo,
    tagInfo,
    isEditable,
    error,
    setError,
    searchInput,
    setSearchInput,
  } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();

  const [companyTags, setCompanyTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const notHrmsTags = currentTags.filter((tag) => tag.tag_source !== "HRMS");

  useEffect(() => {
    if (user && user.tags) {
      setCurrentTags([...user.tags]);
    } else if (device && device.tags) {
      setCurrentTags([...device.tags]);
    }
  }, [user, device]);

  const getCompanyTags = useCallback(async () => {
    let response;

    if (device) {
      response = await currentUser.api.getTagsDevice();
    } else if (user || currentUser) {
      response = await currentUser.api.getTagsUser();
    }
    setCompanyTags(response.data);
  }, [device, user, currentUser]);

  const onInputType = (e) => {
    const newValue = e.target.value;

    setSearchInput(newValue);
    if (error && newValue !== "") {
      setError(""); // Clear the error when the user starts typing
    }
  };

  const onSelectTag = (tag) => {
    if (typeof isChanging === "function") isChanging(true);

    if (tag !== tagInfo && !currentTags.some((t) => t.label.toLowerCase() === tag.label.toLowerCase())) {
      const newTags = [...currentTags, tag];

      setCurrentTags(newTags);
      setSearchInput("");
      onChange(newTags);
    } else {
      addToast(t("Ce tag existe déjà"));
    }
  };

  const normalizeTagLabel = (label) =>
    label
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .replace(/[^a-z0-9]/g, "") // Remove all non-alphanumeric characters
      .trim();

  const onTagCreate = async () => {
    setError("");
    try {
      const trimmedInput = normalizeTagLabel(searchInput);

      // Check if a tag with the same label already exists (case-insensitive)
      const existingTag = companyTags.find((tag) => normalizeTagLabel(tag.label) === trimmedInput);

      if (existingTag) {
        setError(t("L'équipe existe déjà dans votre Cockpit."));
      } else {
        // If the tag doesn't exist, create a new one
        const tagData = {
          label: searchInput,
          type: user ? "USER" : "DEVICE",
        };
        const newTag = await currentUser.api.addTag(tagData);

        // Add to current tags
        onSelectTag(newTag.data);
      }

      if (typeof isChanging === "function") isChanging(true);

      // Reload tag list
      await getCompanyTags();
    } catch (err) {
      addToast(t(err.response?.data?.message || "Une erreur s'est produite lors de la création du tag"));
    }
  };

  const onRemove = (tag) => {
    // Prevent removal of HRMS tags
    if (tag.tag_source === "HRMS") {
      addToast(t("Les tags HRMS ne peuvent pas être supprimés"));

      return;
    }
    const newTags = currentTags.filter((item) => item.id !== tag.id);

    setCurrentTags(newTags);
    onChange(newTags);
    if (typeof isChanging === "function") isChanging(true);
  };

  useEffect(() => {
    if (currentUser.isAdmin()) {
      getCompanyTags();
    }
  }, []);

  const availableTags = companyTags
    // Filter via search
    .filter((t) => searchInput === "" || t.label.toLowerCase().startsWith(searchInput.toLowerCase()))
    // Exclude currentTags
    .filter((t) => !currentTags.find((tag) => tag.id === t.id));

  const onInputEnter = (e) => {
    if (e.key === "Enter" && availableTags.length === 0 && searchInput.length > 1) {
      onTagCreate();
    }
  };

  return (
    <Layout spacing={2} fullWidth>
      <Layout direction="row" spacing={1} flexWrap="wrap" data-cy="selectedTags">
        {notHrmsTags.map((tag) => (
          <TagWithOptionalImages key={tag.id} tag={tag} onIconClick={() => onRemove(tag)} icon={faTimes} />
        ))}
      </Layout>
      <Layout gap={1}>
        <Layout direction="row" gap={1} fullWidth>
          <Layout direction="column" fullWidth gap={0.5}>
            <TextField
              placeholder={t(user ? "Ajouter des équipes" : "Ajouter des tags")}
              type="search"
              value={searchInput}
              onChange={onInputType}
              onKeyDown={onInputEnter}
              sx={{ width: "100%" }}
              error={error}
            />
            {error && (
              <Text color="error" variant="caption">
                {error}
              </Text>
            )}
          </Layout>
          <Button variant="contained" color="secondary" label={t("Créer")} onClick={onTagCreate} />
        </Layout>
        <TagSelectAttribution
          tagsList={availableTags}
          onSelectTag={onSelectTag}
          toggleMenu={toggleMenu}
          setTagInfo={setTagInfo}
          isEditable={isEditable}
        />
      </Layout>
    </Layout>
  );
};

export default TagsAttribution;
