import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Checkbox,
  Heading,
  HorizontalLayout,
  Icon,
  ListCard,
  StackedLayout,
  Text,
  VerticalLayout,
  VerticalSeparator,
} from "tarmac-v2";

import { faBoxArchive, faUser, faUserLargeSlash } from "@fortawesome/pro-regular-svg-icons";

import OldDeviceStatus from "src/components/cockpit/device/blocks/OldDeviceStatus";
import { whichIconCategoryToShow } from "src/tools/IconHelpers";
import ScrollToDiv from "../ScrollToDiv";

import styles from "./ListCard.module.scss";

const DeviceSelectableListCard = ({
  device,
  isActive,
  onCardClick,
  toggleSelected,
  isSelected,
  canSelect = true,
  canHover = true,
  selectMode,
  noCheckbox,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const onHover = () => {
    if (canHover && !selectMode) setIsHovering(true);
  };
  const onQuitHover = () => {
    setIsHovering(false);
  };
  const onCheckboxClicked = (event) => {
    event.stopPropagation();
    if (canSelect) toggleSelected(device);
  };

  const showCheckbox = !noCheckbox && (isHovering || isSelected || selectMode);

  const getImageUrl = () => {
    if (device.source !== "FLEET") return "";
    if (device.product?.product_group?.source === "FLEET") return device.product.img_url;
    return "/images/favicons/favicon-192.png";
  };

  return (
    <ScrollToDiv shouldScrollTo={isActive}>
      <ListCard canHover={canHover} canSelect={canSelect} isActive={isActive} onClick={() => onCardClick(device)}>
        <HorizontalLayout gap="8" alignItems="center">
          <StackedLayout onMouseEnter={onHover} onMouseLeave={onQuitHover}>
            <Avatar
              icon={device && whichIconCategoryToShow(device)}
              variant="device"
              url={getImageUrl()}
              isTransparent={showCheckbox}
            />
            {showCheckbox && (
              <Checkbox style={{ zIndex: "2", fontSize: "20px" }} onClick={onCheckboxClicked} checked={isSelected} />
            )}
          </StackedLayout>
          <VerticalLayout gap="8">
            <Heading className={styles.overflowHeading} size="XS">
              {device.name}
            </Heading>
            <HorizontalLayout gap="8" className={styles.overflowBlock}>
              <OldDeviceStatus device={device} />
              {device.user && (
                <>
                  <VerticalSeparator />
                  <HorizontalLayout gap="8">
                    <Icon size="S" icon={faUser} />
                    <p className={styles.overflowText}>{`${device.user.firstName} ${device.user.lastName}`}</p>
                  </HorizontalLayout>
                </>
              )}
              {!device.user && device.usage_status === "UNASSIGNED" && (
                <>
                  <VerticalSeparator />
                  <HorizontalLayout gap="8">
                    <Icon size="S" icon={faUserLargeSlash} />
                    <Text>{t("Non attribué")}</Text>
                  </HorizontalLayout>
                </>
              )}
              {!device.user && device.usage_status === "SPARE" && (
                <>
                  <VerticalSeparator />
                  <HorizontalLayout gap="8">
                    <Icon size="S" icon={faBoxArchive} />
                    <Text>{t("En réserve")}</Text>
                  </HorizontalLayout>
                </>
              )}
            </HorizontalLayout>
          </VerticalLayout>
        </HorizontalLayout>
      </ListCard>
    </ScrollToDiv>
  );
};

export default DeviceSelectableListCard;
