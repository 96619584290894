export default [
  {
    name: "MONTH_OFFERED",
    text: "Vous bénéficiez de 1 mois offert sur votre commande",
  },
  {
    name: "2_MONTHS_OFFERED",
    text: "Vous bénéficiez de 2 mois offerts sur votre commande",
  },
  {
    name: "3_MONTHS_OFFERED",
    text: "Vous bénéficiez de 3 mois offerts sur votre commande",
  },
];
