import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, LabeledTextField, Text, VerticalLayout } from "tarmac-v2";
import { useHistory } from "react-router-dom";
import useDeviceType from "src/hooks/useDeviceType";
import { useUserContext } from "src/contexts/UserContext";
import styles from "./MissingSerialNumberDialog.module.scss";

const MissingSerialNumberDialog = (props) => {
  const { setOpen, device, onSave, redirection } = props;
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const { user } = useUserContext();
  const [newSerialNumber, setNewSerialNumber] = useState("");
  const history = useHistory();

  const closePopup = () => {
    if (redirection) {
      history.push(redirection);
    }
    setOpen(false);
  };

  const onSubmit = async () => {
    await user.api.modifyDevice(device.id, { serial_number: newSerialNumber });
    if (onSave) {
      onSave();
    }

    if (redirection) {
      history.push(redirection);
    }
    setOpen(null);
  };

  const PrimaryButton = (
    <Button onClick={onSubmit} fullWidth>
      {t("Ajouter un numéro de série")}
    </Button>
  );

  const SecondaryButton = (
    <Button variant="secondary" onClick={closePopup} fullWidth>
      {t("Annuler")}
    </Button>
  );

  return (
    <Dialog
      onClose={closePopup}
      title={t("Numéro de série")}
      primaryButton={PrimaryButton}
      secondaryButton={SecondaryButton}
      {...(deviceType === "mobile" && { buttonContainerProps: { flexWrap: "wrap" } })}
    >
      <VerticalLayout
        gap={16}
        className={["desktop", "widescreen"].includes(deviceType) && styles.informationContainer}
      >
        <Text size="m">
          {t(
            "Pour faciliter la maintenance de votre parc, nous vous recommandons d'ajouter un numéro de série associé à votre ordinateur",
          )}
        </Text>
        <LabeledTextField
          type="text"
          value={newSerialNumber}
          placeholder="12345"
          onChange={(e) => setNewSerialNumber(e.target.value)}
        />
      </VerticalLayout>
    </Dialog>
  );
};

export default MissingSerialNumberDialog;
