import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { HorizontalLayout, Icon, ListCard, TextField, VerticalLayout } from "tarmac-v2";
import { faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useUserContext } from "src/contexts/UserContext";
import { useConfirmModalContext } from "src/contexts/ConfirmModalContext";
import tagColorsAndNames from "src/data/tagColorsAndNames.json";
import styles from "./TagModification.module.scss";

const OldTagModification = (props) => {
  const { tag, setErr, closeMenu, err, setTagInfo } = props;

  const { user: currentUser } = useUserContext();
  const { setConfirmModal } = useConfirmModalContext();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setTagInfo((prevInfo) => ({ ...prevInfo, label: event.target.value }));
  };

  const handleClick = (tagColor) => {
    setTagInfo((prevInfo) => ({ ...prevInfo, color: tagColor }));
  };

  const deleteTag = async () => {
    await currentUser.api.deleteTag(tag.id);
    try {
      setTagInfo("");
      closeMenu();
      setConfirmModal(null);
    } catch (err) {
      setErr(err.response);
    }
  };

  const openConfirmModal = () => {
    const deviceTagType = tag.type === "DEVICE" ? "appareils" : "";
    const userTagType = tag.type === "USER" ? "employés" : "";
    const tagType = deviceTagType || userTagType || "";
    const modalBody = (
      <Trans>
        Cette action va{" "}
        <span className={styles.confirmRemoveTag}>supprimer le Tag et ainsi l'enlever aux {tagType} attribués.</span>
        <br />
        Souhaitez-vous poursuivre ?
      </Trans>
    );

    setConfirmModal({
      onSubmit: deleteTag,
      title: t("Supprimer un tag"),
      children: modalBody,
    });
  };

  const displayErrors = () => {
    if (err.response.request.status === 422) {
      return t(err);
    }

    return null;
  };

  return (
    <VerticalLayout gap="8" fullWidth>
      {t("Tags")}
      {err && (
        <ListCard className={`${styles.error} is-hidden-desktop`}>
          {displayErrors}
          <Icon icon={faTimes} className={styles.icon} onClick={() => setErr(null)} />
        </ListCard>
      )}
      <HorizontalLayout gap="8" justifyContent="space-between">
        <TextField className={styles.renameInput} value={tag.label} onChange={(e) => handleChange(e)} />
        <Icon className={styles.icon} icon={faTrash} onClick={openConfirmModal} />
      </HorizontalLayout>
      <VerticalLayout gap="8">
        {t("Couleur")}
        {tagColorsAndNames.map((c, index) => (
          <ListCard
            key={index}
            onClick={() => handleClick(c.value)}
            className={styles.colorCard}
            isActive={c.value === tag.color}
          >
            <HorizontalLayout gap="16">
              <div className={styles.colorIcon} style={{ backgroundColor: c.color }} />
              {t(c.french_name)}
            </HorizontalLayout>
          </ListCard>
        ))}
      </VerticalLayout>
    </VerticalLayout>
  );
};

export default OldTagModification;
