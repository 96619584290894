import axios from "axios";

let baseUrl = "";

if (import.meta.env.REACT_APP_PR_NUMBER) {
  baseUrl = `https://fleet-co-pr-${import.meta.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
} else {
  baseUrl = import.meta.env.REACT_APP_APIURL;
}

export default axios.create({
  baseURL: `${baseUrl || "/v1"}`,
});
