import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  Avatar,
  Heading,
  HorizontalLayout,
  HorizontalSeparator,
  Icon,
  Link,
  Search,
  Tag,
  Text,
  VerticalLayout,
  VerticalSeparator,
} from "tarmac-v2";
import { faLaptopMobile, faTimes } from "@fortawesome/pro-regular-svg-icons";

import OldDeviceClickableCard from "src/components/cockpit/blocks/listCards/OldDeviceClickableCard";
import { useConfirmModalContext } from "src/contexts/ConfirmModalContext";
import { useUserContext } from "src/contexts/UserContext";
import { useModalContext } from "src/contexts/ModalContext";
import { deviceMatches } from "src/tools/SearchFilters";

import OutsideClickHandler from "src/components/utils/OutsideClickHandler";
import styles from "./UserDevicesModal.module.scss";

const EmployeeDeviceManagement = (props) => {
  const { employee, index, deviceAttribution, updateDeviceAttribution, setDeviceWithoutSerialNumber } = props;
  const { user } = useUserContext();
  const { setModal } = useModalContext();
  const { t } = useTranslation();
  const history = useHistory();

  const [devicesList, setDevicesList] = useState([]);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const { setConfirmModal } = useConfirmModalContext();
  const initials = `${employee?.firstName ? employee.firstName[0] : ""}${employee?.lastName ? employee.lastName[0] : ""}`;
  const devices = devicesList?.filter((d) => deviceAttribution[employee.id].includes(d.id) && d.type === "DEVICE");
  const accessories = devicesList?.filter(
    (d) => deviceAttribution[employee.id].includes(d.id) && d.type === "ACCESSORY",
  );

  // FUNCTIONS

  const addDeviceToEmployee = (device) => {
    const newDeviceAttribution = { ...deviceAttribution };

    const deviceIndex = newDeviceAttribution[employee.id].findIndex((id) => id === device.id);

    if (deviceIndex === -1) {
      newDeviceAttribution[employee.id].push(device.id);
      updateDeviceAttribution(newDeviceAttribution);
    }
    setSearchInputValue("");
  };

  const removeDeviceFromEmployee = (device) => {
    const newDeviceAttribution = { ...deviceAttribution };

    const deviceIndex = newDeviceAttribution[employee.id].findIndex((id) => id === device.id);

    if (deviceIndex >= 0) {
      newDeviceAttribution[employee.id].splice(deviceIndex, 1);
      updateDeviceAttribution(newDeviceAttribution);
    }
  };

  const moveToNewEquipement = () => {
    history.push("/new-device");
    setModal(null);
    setConfirmModal(null);
  };

  useEffect(() => {
    let currentDevices = [...user.getVisibleDevices()];

    if (searchInputValue !== "") {
      currentDevices = currentDevices.filter((d) => deviceMatches(d, searchInputValue));
    }
    setDevicesList(currentDevices);

    const validStep = ["DELIVERED", "BDL_SENT", "RECEIVED", "FINALIZED", "CLOSED"];

    setDeviceWithoutSerialNumber(
      devices.find((d) => d.serial_number === null && d.source === "FLEET" && validStep.includes(d.status)),
    );
  }, [searchInputValue]);

  const modalText = (
    <Trans>
      Vous allez être redirigé vers le formulaire d’ajout d’un nouvel équipement. Une fois créé, vous pourrez reprendre
      le formulaire de gestion d'équipements
    </Trans>
  );

  return (
    <div key={`employee-${index}`} className={styles.employeeCard}>
      <VerticalLayout gap={16}>
        <HorizontalLayout gap={8}>
          <Avatar letters={initials.toUpperCase()} url={employee.photo_url} />
          <VerticalLayout gap={8}>
            <Heading size="XS">{`${employee.firstName} ${employee.lastName}`}</Heading>

            <HorizontalLayout gap={8}>
              {employee?.tags?.length > 0 && (
                <Tag filled small color={employee.tags[0].color} label={employee.tags[0].label} />
              )}

              {employee?.used_devices?.length > 0 && (
                <>
                  <VerticalSeparator />
                  <HorizontalLayout gap={8}>
                    <Icon size="S" icon={faLaptopMobile} />
                    <Text size="M">{employee.used_devices.length}</Text>
                  </HorizontalLayout>
                </>
              )}
            </HorizontalLayout>
          </VerticalLayout>
        </HorizontalLayout>
        <HorizontalSeparator />
        <div className={styles.inputDevicesContainer}>
          <Search
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            placeholder={t("Rechercher par numéro de série, nom...")}
            autoFocus={searchFocus}
            onBlur={() => !searchInputValue && setSearchFocus(false)}
            isOpen
          />
          <OutsideClickHandler action={() => setSearchInputValue("")}>
            {searchInputValue !== "" && (
              <VerticalLayout gap={8} className={styles.devicesListWrapper}>
                <HorizontalLayout gap={8} justifyContent="space-between" className={styles.addDeviceBlock}>
                  <Text size="S" bold>
                    {t("Pas dans la liste ?")}
                  </Text>
                  <Link
                    onClick={() => {
                      setConfirmModal({
                        title: t("Créer un nouvel appareil"),
                        onSubmit: () => moveToNewEquipement(),
                        children: modalText,
                      });
                    }}
                  >
                    {t("Créer un nouvel appareil")}
                  </Link>
                </HorizontalLayout>
                {devicesList.map((device, i) => (
                  <OldDeviceClickableCard
                    key={i}
                    device={device}
                    canHover
                    displayAssignee
                    userToShow={`${device.user?.firstName || ""} ${device.user?.lastName || ""}`}
                    onClick={() => addDeviceToEmployee(device)}
                  />
                ))}
              </VerticalLayout>
            )}
          </OutsideClickHandler>
        </div>
        {devices?.length > 0 && (
          <VerticalLayout gap={8}>
            <Text size="S" bold>
              {t("Appareils")} ({devices.length})
            </Text>
            {devices.map((d, index) => (
              <HorizontalLayout gap={8} key={`device-${index}`} alignItems="center">
                <OldDeviceClickableCard device={d} className={styles.deviceCard} />
                <Icon icon={faTimes} onClick={() => removeDeviceFromEmployee(d)} style={{ cursor: "pointer" }} />
              </HorizontalLayout>
            ))}
          </VerticalLayout>
        )}
        {accessories?.length > 0 && (
          <VerticalLayout gap={8}>
            <Text size="S" bold>
              {t("Accessoires")} ({accessories.length})
            </Text>
            {accessories.map((d, index) => (
              <HorizontalLayout gap={8} key={`accessory-${index}`} alignItems="center">
                <OldDeviceClickableCard device={d} className={styles.deviceCard} />
                <Icon icon={faTimes} onClick={() => removeDeviceFromEmployee(d)} style={{ cursor: "pointer" }} />
              </HorizontalLayout>
            ))}
          </VerticalLayout>
        )}
      </VerticalLayout>
    </div>
  );
};

export default EmployeeDeviceManagement;
