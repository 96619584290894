import React from "react";

import { useUserContext } from "src/contexts/UserContext";
import { useModalContext } from "src/contexts/ModalContext";

import AssignAllModal from "./assign/AssignAllModal";
import AssignModal from "./assign/AssignModal";
import TaggingAllModal from "./tagging/TaggingAllModal";
import TaggingModal from "./tagging/TaggingModal";
import CatalogFiltersModal from "./filters/CatalogFiltersModal";
import DevicesFiltersModal from "./filters/DevicesFiltersModal";
import EmployeesFiltersModal from "./filters/EmployeesFiltersModal";
import CompanyAddresses from "./address/CompanyAddressesModal";
import TaggingUserModal from "./userTagging/TaggingUserModal";
import TaggingAllUserModal from "./userTagging/TaggingAllModal";
import UserDevicesModal from "./device/UserDevicesModal";
import ShoppingCartModal from "./shoppingCart/ShoppingCartModal";
import SignatoryFilesModal from "./signatory/reorder/OldSignatoryFilesModal";
import CompanyFilesModal from "./signatory/reorder/CompanyFilesModal";
import SettingsSignatoryModal from "./signatory/settings/SettingsSignatoryModal";
import CreateEmployeeModal from "./employee/CreateEmployee";
import OldManageRolesAndNoticesModal from "./employee/OldManageRolesAndNoticesModal";
import ShoppingListModal from "./shoppingList/ShoppingListModal";

const Modals = () => {
  const { modal, setModal } = useModalContext();
  const { reloadUser } = useUserContext();

  if (modal === null) {
    return null;
  }

  const onCloseModal = () => {
    const shouldReloadUser = modal.props.shouldReloadUser !== undefined ? modal.props.shouldReloadUser : true;

    if (modal.props.onClose) {
      modal.props.onClose();
    }

    if (shouldReloadUser) {
      reloadUser();
    }

    setModal(null);
  };

  return (
    <>
      {modal.name === "ASSIGN" && <AssignModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "ASSIGN_ALL" && <AssignAllModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "TAGGING" && <TaggingModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "TAGGING_ALL" && <TaggingAllModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "TAGGING_USER" && <TaggingUserModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "TAGGING_ALL_USER" && <TaggingAllUserModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "SHOPPING_LIST_EDIT" && <ShoppingListModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "CATALOG_FILTERS" && <CatalogFiltersModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "DEVICES_FILTERS" && <DevicesFiltersModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "EMPLOYEES_FILTERS" && <EmployeesFiltersModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "ADDRESS" && <CompanyAddresses {...modal.props} onClose={onCloseModal} />}
      {modal.name === "USER_DEVICES" && <UserDevicesModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "SHOPPING_CART" && <ShoppingCartModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "SIGNATORY_FILES" && <SignatoryFilesModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "COMPANY_FILES" && <CompanyFilesModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "SETTINGS_SIGNATORY" && <SettingsSignatoryModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "CREATE_EMPLOYEE" && <CreateEmployeeModal {...modal.props} onClose={onCloseModal} />}
      {modal.name === "OLD_ROLES_AND_NOTIFICATIONS" && (
        <OldManageRolesAndNoticesModal {...modal.props} onClose={onCloseModal} />
      )}
    </>
  );
};

export default Modals;
