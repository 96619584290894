import React from "react";
import { Trans, useTranslation } from "react-i18next";
import useDeviceType from "src/hooks/useDeviceType";
import { Card, Heading, Layout, Text, useTheme } from "tarmac-v3";

const LoginEmailSent = () => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";
  const theme = useTheme();

  return (
    <Card sx={{ width: isMobile ? "100%" : 450 }} spacing={isMobile ? 2 : 3}>
      <Layout direction="column" spacing={4}>
        <Layout direction="column" alignItems="center">
          <Trans>
            <Heading variant="h3" textAlign="center">
              Un <span style={{ color: theme.palette.green[500] }}>lien de connexion</span> vous a été envoyé
            </Heading>
          </Trans>
        </Layout>

        <Layout>
          <Text textAlign="center">
            {t("Veuillez vérifier votre boîte mail, nous venons de vous envoyer un lien de connexion temporaire.")}
          </Text>
        </Layout>
        <Layout>
          <Text variant="body2" color="secondary" textAlign="center">
            {t("Vous ne trouvez pas votre mail ? Vérifiez votre dossier de courrier indésirable.")}
          </Text>
        </Layout>
      </Layout>
    </Card>
  );
};

export default LoginEmailSent;
