import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const initialFilters = {
  search: "",
  category: "",
  brands: [],
  usages: [],
  screenSizes: [],
  memories: [],
  processors: [],
  storages: [],
  connectivities: [],
  qualities: [],
  graphics: [],
  keyboardLayouts: [],
  noiseCancellation: null,
  hasMicrophone: null,
  connectionTypes: [],
  compliances: [],
  refreshRates: [],
  responseTimes: [],
  screenStands: [],
  definitions: [],
  optimizedTo: [],
  types: [],
  seatsNumbers: [],
  heights: [],
  armrests: [],
  lumbarSupports: [],
  dimensions: [],
  hasStool: null,
  hasVideoPackage: null,
  hasPremiumTablet: null,
  hasScreen: null,
  hasPowerPlug: null,
  hasAdjustableHeight: null,
  hasCableManagement: null,
  hasWheels: null,
  deliveryMaxTime: null,
  priceRangeMin: null,
  priceRangeMax: null,
  weight: null,
};

const useCatalogFilters = () => {
  const [filters, setFilters] = useState({ ...initialFilters });
  const isUnmounted = useRef(false);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  const onChangeFilters = useCallback(
    (field, value) => {
      const newFilters = { ...filters };

      switch (field) {
        case "deliveryMaxTime":
          newFilters.deliveryMaxTime = newFilters.deliveryMaxTime === parseInt(value, 10) ? null : parseInt(value, 10);
          break;

        case "priceRangeMin":
        case "priceRangeMax":
          newFilters[field] = value ? parseInt(value, 10) : null;
          break;

        case "weight":
          newFilters[field] = newFilters[field] === value ? null : value;
          break;

        case "search":
          newFilters[field] = value;
          break;

        case "category":
          newFilters.category = newFilters.category === value ? "" : value;
          break;

        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
          newFilters[field] = filters[field] === value ? null : value;
          break;

        default:
          newFilters[field] = [...filters[field]];

          if (newFilters[field].includes(value)) {
            newFilters[field].splice(newFilters[field].indexOf(value), 1);
          } else {
            newFilters[field].push(value);
          }

          break;
      }

      setFilters(newFilters);
    },
    [filters],
  );

  const resetFilter = useCallback(
    (key) => {
      const newFilters = { ...filters };

      switch (key) {
        case "search":
        case "category":
          break;

        case "priceRangeMin":
        case "priceRangeMax":
        case "deliveryMaxTime":
        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
        case "weight":
          newFilters[key] = null;
          break;

        default:
          newFilters[key] = [];
          break;
      }

      setFilters(newFilters);
    },
    [filters],
  );

  const resetFilters = useCallback(() => {
    const newFilters = { ...filters };

    for (const key in newFilters) {
      switch (key) {
        case "search":
        case "category":
          break;

        case "priceRangeMin":
        case "priceRangeMax":
        case "deliveryMaxTime":
        case "hasMicrophone":
        case "noiseCancellation":
        case "hasStool":
        case "hasVideoPackage":
        case "hasPremiumTablet":
        case "hasScreen":
        case "hasPowerPlug":
        case "hasAdjustableHeight":
        case "hasCableManagement":
        case "hasWheels":
        case "weight":
          newFilters[key] = null;
          break;

        default:
          newFilters[key] = [];
          break;
      }
    }

    setFilters(newFilters);
  }, [filters]);

  const filtersCount = useMemo(
    () =>
      Object.keys(filters).reduce((acc, filterKey) => {
        let newCount = acc;

        if (Array.isArray(filters[filterKey])) {
          newCount += filters[filterKey].length;
        } else if (typeof filters[filterKey] === "string" && filterKey !== "weight") {
          newCount += 0;
        } else {
          newCount += 1;
        }

        return newCount;
      }, 0),
    [filters],
  );

  return {
    filters,
    initialFilters,
    setFilters,
    resetFilter,
    resetFilters,
    onChangeFilters,
    filtersCount,
  };
};

export default useCatalogFilters;
