import React, { useEffect, useState } from "react";
import { useUserContext } from "src/contexts/UserContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCatalogNavigation from "src/hooks/useCatalogNavigation";
import MoneyFormat from "src/tools/MoneyFormat";
import CImage from "src/components/cockpit/blocks/CImage";
import { Card, Heading, Layout, Tag, Text } from "tarmac-v3";

const getMaxShippingProduct = (products) =>
  products.reduce((maxProduct, product) => {
    const maxShipping = parseInt(product.max_shipping, 10);
    const currentMaxShipping = parseInt(maxProduct.max_shipping, 10);

    return maxShipping < currentMaxShipping ? product : maxProduct;
  });

const ProductGroupCardNarrow = (props) => {
  const { index, productGroup, showFastShippingTag, onClose } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { user: currentUser } = useUserContext();
  const { getProductGroupUrl } = useCatalogNavigation();

  const [minBasePrice, setMinBasePrice] = useState(0);
  const [minFinalPrice, setMinFinalPrice] = useState(0);
  const [fastestShippingProduct, setFastestShippingProduct] = useState(0);

  useEffect(() => {
    if (productGroup.products.length) {
      setFastestShippingProduct(getMaxShippingProduct(productGroup.products));

      const prices = currentUser.getProductPrices(productGroup);

      setMinBasePrice(prices.minBasePrice);
      setMinFinalPrice(prices.minFinalPrice);
    }
  }, [productGroup.products, currentUser]);

  const basePrice = Number(minBasePrice).toFixed(2);
  const finalPrice = Number(minFinalPrice).toFixed(2);

  const pgUrl = getProductGroupUrl(productGroup);

  const closeCard = () => {
    history.push(pgUrl);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Card
      data-cy={`productGroupCard-${index}`}
      key={`productGroupCard-${index}`}
      spacing={3}
      onClick={closeCard}
      style={{ flex: "0 0 33.3333%" }}
    >
      <Layout direction="column" flexGrow={1} justifyContent="space-between" fullHeight flex={1} fullWidth>
        <Tag
          small
          backgroundColor="green"
          variant="filled"
          label={t("{{min}} à {{max}} jours ouvrés", {
            min: fastestShippingProduct.min_shipping,
            max: fastestShippingProduct.max_shipping,
          })}
          sx={{ width: "fit-content", visibility: showFastShippingTag ? "visible" : "hidden" }}
        />
        <Layout direction="column" spacing={2}>
          <Layout direction="row" flexGrow={1} spacing={2} justifyContent="center">
            <CImage
              fetchUrl={productGroup.img_url}
              alt="product"
              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
            />
          </Layout>
          <Layout direction="column" alignItems="flex-start">
            <Heading variant="h6">{t(productGroup.name)}</Heading>
          </Layout>
        </Layout>
        <Layout direction="column">
          <Layout direction="row" spacing={1} alignItems="center">
            <Text variant="body2" color="secondary">
              {t("à partir de")}
            </Text>
            {basePrice !== finalPrice && (
              <Text
                variant="body1"
                color="secondary"
                bold
                sx={{
                  textDecoration: "line-through",
                }}
              >
                <MoneyFormat value={basePrice} />
              </Text>
            )}
          </Layout>
          <Layout direction="row" spacing={1} alignItems="baseline">
            <Heading variant="h4">
              <MoneyFormat value={finalPrice} />
            </Heading>
            <Text variant="caption">{t("/mois")}</Text>
          </Layout>
        </Layout>
      </Layout>
    </Card>
  );
};

export default ProductGroupCardNarrow;
