import React, { useEffect, useMemo, useRef, useState } from "react";

const ScrollToDiv = ({ children, shouldScrollTo }) => {
  const myRef = useRef(null);

  const [isVisible, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    observer.observe(myRef.current);

    return () => {
      observer.disconnect();
    };
  }, [myRef, observer]);

  useEffect(() => {
    if (shouldScrollTo && !isVisible) {
      if (myRef.current) {
        myRef.current.scrollIntoView();
      }
    }
  }, [shouldScrollTo, myRef]);

  return <div ref={myRef}>{children}</div>;
};

export default ScrollToDiv;
