import React from "react";
import { Avatar, Tag } from "tarmac-v3";

const TagWithOptionalImages = ({ tag, onIconClick, icon }) => {
  const { label, color: backgroundColor, border_color: borderColor, tag_source: tagSource, avatar } = tag;

  return (
    <Tag
      label={label}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      variant="filled"
      avatar={avatar && <Avatar src={avatar} />}
      onIconClick={onIconClick}
      icon={icon}
      size="small"
      sx={{
        height: "24px",
        paddingLeft: tagSource === "HRMS" ? "4px" : "none",
        "& .MuiChip-label": {
          paddingLeft: avatar && 1,
        },
      }}
    />
  );
};

export default TagWithOptionalImages;
