import { COUNTRIES } from "src/common/i18n-consts";

/* eslint-disable import/prefer-default-export */
export const getMandateTexts = (country) => {
  switch (country) {
    case COUNTRIES.UNITED_KINGDOM:
      return { mandateLabel: "Débit Direct", mandateDescription: "débit direct" };
    case COUNTRIES.UNITED_STATES:
      return { mandateLabel: "Carte bancaire", mandateDescription: "prélèvement par carte bancaire" };
    default:
      return { mandateLabel: "Mandat SEPA", mandateDescription: "prélèvement SEPA" };
  }
};
