import { createContext, useContext } from "react";

export const MandateManagerContext = createContext();

export const useMandateManagerContext = () => {
  const context = useContext(MandateManagerContext);

  if (!context) {
    throw new Error("useMandateManagerContext must be used within a MandateManagerProvider");
  }

  return context;
};
