import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useFunnelNavigation from "src/hooks/useFunnelNavigation";
import { categoryGroups } from "src/tools/ProductHelpers";

const catalogBreadcrumb = [
  {
    type: "PAGE",
    name: "HOME",
    slug: "/catalog",
    label: "Accueil catalogue",
  },
  {
    type: "PAGE",
    name: "SEARCH",
    slug: "/catalog/search",
    label: "Vos résultats",
  },
  {
    type: "PAGE",
    name: "SHOPPING_LIST",
    slug: "/catalog/shopping-list",
    label: "Listes d’achats",
  },
  {
    type: "UNIVERSE",
    name: "HARDWARE",
    slug: "/catalog/category/hardware",
    label: "Informatique",
  },
  {
    type: "CATEGORY_GROUP",
    name: "DEVICE",
    slug: "/catalog/category/hardware/device",
    label: "Appareils",
  },
  {
    type: "CATEGORY",
    name: "COMPUTER",
    slug: "/catalog/category/hardware/device/computer",
    label: "Ordinateurs",
  },
  {
    type: "CATEGORY",
    name: "TABLET",
    slug: "/catalog/category/hardware/device/tablet",
    label: "Tablettes",
  },
  {
    type: "CATEGORY",
    name: "SMARTPHONE",
    slug: "/catalog/category/hardware/device/smartphone",
    label: "Téléphones",
  },
  {
    type: "CATEGORY_GROUP",
    name: "ACCESSORY",
    slug: "/catalog/category/hardware/accessory",
    label: "Accessoires",
  },
  {
    type: "CATEGORY",
    name: "SCREEN",
    slug: "/catalog/category/hardware/accessory/screen",
    label: "Écrans & TV",
  },
  {
    type: "CATEGORY",
    name: "HEADPHONES",
    slug: "/catalog/category/hardware/accessory/headphones",
    label: "Casques",
  },
  {
    type: "CATEGORY",
    name: "SPEAKERS",
    slug: "/catalog/category/hardware/accessory/speakers",
    label: "Haut-parleurs",
  },
  {
    type: "CATEGORY",
    name: "KEYBOARD",
    slug: "/catalog/category/hardware/accessory/keyboard",
    label: "Claviers",
  },
  {
    type: "CATEGORY",
    name: "MOUSE",
    slug: "/catalog/category/hardware/accessory/mouse",
    label: "Souris",
  },
  {
    type: "CATEGORY",
    name: "ADAPTER",
    slug: "/catalog/category/hardware/accessory/adapter",
    label: "Adaptateurs",
  },
  {
    type: "CATEGORY",
    name: "CHARGER",
    slug: "/catalog/category/hardware/accessory/charger",
    label: "Chargeurs",
  },
  {
    type: "CATEGORY",
    name: "CASE",
    slug: "/catalog/category/hardware/accessory/case",
    label: "Housses",
  },
  {
    type: "UNIVERSE",
    name: "FURNITURE",
    slug: "/catalog/category/furniture",
    label: "Mobilier",
  },
  {
    type: "CATEGORY_GROUP",
    name: "ACOUSTIC",
    slug: "/catalog/category/furniture/acoustic",
    label: "Aménagements acoustiques",
  },
  {
    type: "CATEGORY",
    name: "PHONEBOOTH",
    slug: "/catalog/category/furniture/acoustic/phonebooth",
    label: "Cabines acoustiques",
  },
  {
    type: "CATEGORY_GROUP",
    name: "SEAT",
    slug: "/catalog/category/furniture/seat",
    label: "Sièges",
  },
  {
    type: "CATEGORY",
    name: "CHAIR",
    slug: "/catalog/category/furniture/seat/chair",
    label: "Chaises",
  },
  {
    type: "CATEGORY_GROUP",
    name: "TABLE",
    slug: "/catalog/category/furniture/table",
    label: "Tables",
  },
  {
    type: "CATEGORY",
    name: "DESK",
    slug: "/catalog/category/furniture/table/desk",
    label: "Bureaux",
  },
];

const breadcrumbOptions = {
  catalog: catalogBreadcrumb,
  onboarding: catalogBreadcrumb,
  "renewal-order": catalogBreadcrumb.map((bc) => ({ ...bc, slug: bc.slug.replace("/catalog", "/renewal-order") })),
};

const useCatalogNavigation = () => {
  const { pathname } = useLocation();
  const { funnel } = useFunnelNavigation();
  const isEmbed = funnel !== "catalog";
  const isUnmounted = useRef(false);
  const isRenewal = pathname.includes("/renewal-order");

  const offset = isRenewal ? 3 : 1; // Set the desired offset
  const parts = pathname.split("/").slice(offset); // Adjust starting position based on offset

  // This will return a string with the origin path
  // If my url is cockpit.fleet.co/catalog/category/hardware the originPath will be cockpit.fleet.co
  // If my url is cockpit.fleet.co/renewal/5049/renewal-order/catalog/category/hardware the originPath will be cockpit.fleet.co/renewal/5049
  const originPath = pathname.split("/").slice(0, offset).join("/");

  const [mainPage, subPage, ...paths] = parts; // Destructure the adjusted array

  const breadcrumb = breadcrumbOptions[mainPage]?.map((bc) => pathname.includes(bc.slug) && bc).filter(Boolean) || [];

  const isInProductPage = paths.length === 4;

  if (isInProductPage) {
    breadcrumb.push({ type: "PRODUCT" });
  }

  const homeUrl = `${originPath}/${mainPage}`;

  const getProductGroupUrl = (productGroup) => {
    const categoryGroup = categoryGroups.find((cg) => cg.categories.includes(productGroup.category));
    const universeName =
      breadcrumb?.find((bc) => bc.type === "UNIVERSE")?.name?.toLowerCase() || categoryGroup?.universe;

    const categoryGroupName = categoryGroup?.name || "";

    const categoryName = productGroup.category?.toLowerCase();

    const productGroupSlug = productGroup.slug;

    const groupUrl = `${homeUrl}/category/${universeName}/${categoryGroupName}/${categoryName}/${productGroupSlug}`;

    return groupUrl;
  };

  const getUniverseUrl = (universe) => `${homeUrl}/category/${universe.toLowerCase()}`;

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  return {
    isEmbed,
    mainPage,
    subPage,
    breadcrumb,
    isInProductPage,
    isRenewal,
    originPath,
    getProductGroupUrl,
    getUniverseUrl,
    homeUrl,
    ...(isInProductPage && { productSlug: paths[paths.length - 1] }),
  };
};

export default useCatalogNavigation;
