import { LANGUAGES, LANGUAGES_LIST, LANGUAGE_VARIATIONS_LIST } from "src/common/i18n-consts";

export default function useInitialSelectedLanguage(source) {
  let initialSelectedLanguage = LANGUAGES.EN;
  let initialSelectedLanguageVariation = LANGUAGES.EN;

  const sourceLanguage = LANGUAGES_LIST.includes(source.language) ? source.language : null;
  const shortNavigatorLanguage = navigator.language.substring(0, 2);
  const navigatorLanguage = LANGUAGES_LIST.includes(shortNavigatorLanguage) ? shortNavigatorLanguage : LANGUAGES.EN;

  initialSelectedLanguage = localStorage.getItem("user_language") || sourceLanguage || navigatorLanguage;

  const sourceLanguageVariation = LANGUAGE_VARIATIONS_LIST.includes(source.languageVariation)
    ? source.languageVariation
    : null;
  const shortNavigatorLanguageVariation = navigator.language.substring(3, 5).toLowerCase();
  const navigatorLanguageVariation = LANGUAGE_VARIATIONS_LIST.includes(shortNavigatorLanguageVariation)
    ? shortNavigatorLanguageVariation
    : initialSelectedLanguage;

  initialSelectedLanguageVariation =
    localStorage.getItem("user_language_variation") || sourceLanguageVariation || navigatorLanguageVariation;

  return {
    initialSelectedLanguage,
    initialSelectedLanguageVariation,
  };
}
