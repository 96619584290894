import React, { useCallback, useEffect, useState } from "react";
import { Button, Heading, LabeledTextField, Text, VerticalLayout } from "tarmac-v2";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToastContext } from "src/contexts/ToastContext";
import getLocalePlaceholder from "src/components/utils/getLocalePlaceholder";
import { useUserContext } from "src/contexts/UserContext";
import styles from "./CompleteInfoEmployee.module.scss";

const CompleteInfoEmployee = () => {
  // const { source } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { user: currentUser, reloadUser } = useUserContext();
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { addToast } = useToastContext();

  const onSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await currentUser.api.modifyUser(currentUser.id, { email });
      reloadUser();
    } catch (e) {
      setIsLoading(false);
      addToast(t(e.response?.data?.message || "Une erreur est survenue."));
    }
    setIsLoading(false);
  }, [addToast, currentUser, email, reloadUser, t]);

  // add event listener to save the form when the user press enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        onSave();
      }
    };

    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [onSave]);

  useEffect(() => {
    if (currentUser?.email) {
      history.push("/");
    }
  }, [currentUser, history]);

  return (
    <VerticalLayout
      className={`${styles.mainWrapper} ${styles.bgImg}`}
      fullWidth
      isScrollable
      justifyContent="center"
      alignItems="center"
    >
      <VerticalLayout className={styles.dialogWrapper} switchToColumnInMobile alignItems="center" gap={24}>
        <Heading size="M">
          {currentUser?.company?.name
            ? t("Bienvenue sur le Cockpit de {{companyName}}", { companyName: currentUser?.company?.name })
            : t("Bienvenue sur le Cockpit")}
        </Heading>
        <Text size="M">{t("Saisissez votre adresse e-mail professionnelle pour accéder à votre espace employé.")}</Text>

        <LabeledTextField
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          name="email"
          placeholder={getLocalePlaceholder(i18n.language, "email")}
          indication="*"
        >
          {t("E-mail professionnel")}
        </LabeledTextField>
        <VerticalLayout alignItems="flex-end" justifyContent="flex-end" fullWidth fullHeight>
          <Button isLoading={isLoading} onClick={onSave}>
            {t("Valider")}
          </Button>
        </VerticalLayout>
      </VerticalLayout>
    </VerticalLayout>
  );
};

export default CompleteInfoEmployee;
