import leasersList from "src/data/leasersList";

/**
 * Get the contract starting date.
 * This is a DUPLICATE of the getContractStartDate method of the Order model.
 * @param {import("../common/types/Order.type").Order} order - The order object
 * @returns {Date} The contract starting date
 */
const getContractStartingDate = (order) => {
  // Not yet received : consider billing starts now
  if (!order.reception_date) {
    return new Date();
  }

  if (order.billing_frequency === "MONTHLY") {
    // Create date in UTC to avoid timezone issues
    const receptionDate = new Date(order.reception_date);

    // Special case  : Locam & NBB
    if (order.leaser === "Locam" || order.leaser === "NBB Lease") {
      const day = receptionDate.getUTCDate();
      const month = receptionDate.getUTCMonth();
      const year = receptionDate.getUTCFullYear();

      if (day < 11) {
        // If February, set to last day of February
        if (month === 1) {
          return new Date(Date.UTC(year, 2, 0)); // Last day of February
        }
        // Otherwise set to 30th of current month
        return new Date(Date.UTC(year, month, 30));
      }
      if (day < 21) {
        // 10th of next month
        return new Date(Date.UTC(year, month + 1, 10));
      }
      // 20th of next month
      return new Date(Date.UTC(year, month + 1, 20));
    }
    // First day of next month
    return new Date(Date.UTC(receptionDate.getUTCFullYear(), receptionDate.getUTCMonth() + 1, 1, 0, 0, 0));
  }

  if (order.billing_frequency === "QUARTERLY") {
    const receptionDate = new Date(order.reception_date);
    const month = receptionDate.getUTCMonth();
    const year = receptionDate.getUTCFullYear();

    if (month < 3) {
      return new Date(Date.UTC(year, 3, 1)); // April 1st
    }
    if (month < 6) {
      return new Date(Date.UTC(year, 6, 1)); // July 1st
    }
    if (month < 9) {
      return new Date(Date.UTC(year, 9, 1)); // October 1st
    }
    return new Date(Date.UTC(year, 11, 1)); // December 1st
  }

  return new Date(order.reception_date);
};

const getContractEndingDate = (order) => {
  const startingDate = new Date(getContractStartingDate(order));
  const endingDate = startingDate.setMonth(getContractStartingDate(order).getMonth() + order.contract_duration);

  return endingDate;
};

const getContractRenewalDate = (order) => {
  const startingDate = new Date(getContractStartingDate(order));
  const endingDate = new Date(
    startingDate.setMonth(getContractStartingDate(order).getMonth() + order.contract_duration),
  );

  return endingDate.setDate(endingDate.getDate() + 3);
};

// Calculate difference between two dates in months
const monthsDiff = (d1, d2) => {
  const years = d2.getFullYear() - d1.getFullYear();
  const months = years * 12 + (d2.getMonth() - d1.getMonth());

  return months;
};

const getNumberMonthsPassed = (order) => {
  const today = new Date();

  return monthsDiff(getContractStartingDate(order), today);
};

const getNumberMonthsLeft = (order) => order.contract_duration - getNumberMonthsPassed(order);

const groupByObjectKey = (order, groupKey) =>
  order?.devices?.reduce((result, item) => {
    const newResult = { ...result };

    if (newResult[item[groupKey]]?.length) {
      newResult[item[groupKey]].push(item);
    } else {
      newResult[item[groupKey]] = [item];
    }

    return newResult;
  }, {}) || {};

const getOrderStatusInfo = (order) => {
  let orderStatus = { label: "", color: "" };
  const notSendBl = leasersList.filter((leaser) => leaser?.automaticMailLeaser);

  const noNeedSendBl =
    order.company?.country === "France" || notSendBl.map((leaser) => leaser.name).includes(order?.leaser);

  const getClosedSpecificStatus = () => {
    if (getNumberMonthsLeft(order) > 2) {
      return {
        label: `${getNumberMonthsLeft(order)} mois restants`,
        filterLabel: "ongoing_order",
        color: "success",
        monthsLeft: getNumberMonthsLeft(order),
      };
    }

    if (!order.renewal_decision) {
      return {
        label: "Décision à prendre",
        filterLabel: "pending_decision",
        color: "warning",
      };
    }

    if (order.devices.some((device) => ["RENEW", "STOP", "BROKEN", "UPGRADE"].includes(device.renewal_type))) {
      return {
        label: "Équipement à renvoyer",
        filterLabel: "equipment_to_send",
        color: "warning",
      };
    }

    return { label: `${getNumberMonthsLeft(order)} mois restants`, color: "success" };
  };

  // If something changes in the order statuses, please update OrdersQuickFilters.jsx

  switch (order.status) {
    case "LEAD_AGREEMENT":
    case "LEASER_REQUESTED":
      orderStatus.label = "Demande de financement";
      orderStatus.filterLabel = "financing_request";
      orderStatus.color = "pending";
      break;
    case "LEASER_MORE_DOCUMENTS":
      orderStatus.label = "Documents à ajouter";
      orderStatus.filterLabel = "doc_required";
      orderStatus.color = "warning";
      break;
    case "LEAD_SIGNING":
      orderStatus.label = "Contrat à signer";
      orderStatus.filterLabel = "signing";
      orderStatus.color = "warning";
      break;
    case "READY_FOR_PURCHASE":
    case "PURCHASED":
      orderStatus.label = "En préparation";
      orderStatus.filterLabel = "processing";
      orderStatus.color = "pending";
      break;
    case "SHIPPED":
      orderStatus.label = "En livraison";
      orderStatus.filterLabel = "shipped";
      orderStatus.color = "pending";
      break;
    case "DELIVERED":
    case "BDL_SENT":
      orderStatus.label = noNeedSendBl ? "Livrée" : "Réception à confirmer";
      orderStatus.filterLabel = noNeedSendBl ? "received" : "reception_to_confirm";
      orderStatus.color = noNeedSendBl ? "success" : "warning";
      break;

    case "RECEIVED":
    case "FINALIZED":
      orderStatus.label = "Livrée";
      orderStatus.filterLabel = "received";
      orderStatus.color = "success";
      break;

    case "CLOSED":
      orderStatus = getClosedSpecificStatus();
      break;

    case "INACTIVE":
      orderStatus.label = "Archivée";
      orderStatus.filterLabel = "archived";
      orderStatus.color = "secondary";
      break;

    case "FAILED":
      orderStatus.label = "Accord non obtenu";
      orderStatus.filterLabel = "failed";
      orderStatus.color = "error";
      break;

    default:
      orderStatus.label = "-";
      orderStatus.filterLabel = "-";
      orderStatus.color = "disabled";
  }

  return orderStatus;
};

const applyOrderNumberFilters = (orders, filters) =>
  orders.filter((order) => {
    if (filters.order_number.length === 0) {
      return true;
    }

    if (filters.order_number.includes("NONE") && !order.order_number) {
      return true;
    }

    if (filters.order_number.includes(order.order_number)) {
      return true;
    }

    return false;
  });

const applyContractNumberFilters = (orders, filters) =>
  orders.filter((order) => {
    if (filters.contract_number.length === 0) {
      return true;
    }

    if (filters.contract_number.includes("NONE") && (!order.contract_number || !order.request_number)) {
      return true;
    }

    if (
      filters.contract_number.includes(order.contract_number) ||
      filters.contract_number.includes(order.request_number)
    ) {
      return true;
    }

    return false;
  });

const applyStatusFilters = (orders, filters) =>
  orders.filter((order) => {
    const orderStatus = getOrderStatusInfo(order).filterLabel;

    if (filters.status.length === 0) {
      return true;
    }

    if (
      filters.status.includes("ongoing_order") &&
      ["ongoing_order", "pending_decision", "equipment_to_send"].includes(getOrderStatusInfo(order).filterLabel)
    ) {
      return true;
    }

    if (filters.status.includes(orderStatus)) {
      return true;
    }

    return false;
  });

const applyBasicFilters = (orders, filters, field) =>
  orders.filter((order) => {
    if (filters[field].length === 0) {
      return true;
    }

    return filters[field].includes(order[field]);
  });

const groupDevicesByProductAndKeyboard = (devices) => {
  if (!devices) return {};

  return devices.reduce((acc, item) => {
    // Create a copy of the data structure
    const updatedResult = { ...acc };

    // Initialisation of keys if they don't exist yet
    if (!updatedResult[item.product_id]) {
      updatedResult[item.product_id] = {};
    }

    if (!updatedResult[item.product_id][item.keyboard_layout]) {
      updatedResult[item.product_id][item.keyboard_layout] = {};
    }

    if (!updatedResult[item.product_id][item.keyboard_layout][item.order_mdm_activation_consent]) {
      updatedResult[item.product_id][item.keyboard_layout][item.order_mdm_activation_consent] = [];
    }

    // Add element to the updated data structure
    updatedResult[item.product_id][item.keyboard_layout][item.order_mdm_activation_consent].push(item);

    return updatedResult;
  }, {});
};

const isContractEnded = (order) => {
  if (!order || !order.reception_date) return false;
  const today = new Date();

  if (!order.contract_end_date) {
    const receptionDate = new Date(order?.reception_date);
    const endDate = new Date(receptionDate);

    endDate.setMonth(endDate.getMonth() + order.contract_duration);

    return today > endDate;
  }
  const endingDate = new Date(order.contract_end_date);

  return today > endingDate;
};

const isContractEndingSoon = (order) => {
  if (!order.contract_end_date) return false;

  const today = new Date();
  const endingDate = new Date(order.contract_end_date);

  return monthsDiff(today, endingDate) <= 3;
};

const isContractOlderOneMonth = (order) => {
  if (!order.contract_start_date) return false;

  const today = new Date();
  const startDate = new Date(order.contract_start_date);

  return monthsDiff(startDate, today) >= 1;
};

export {
  getOrderStatusInfo,
  getContractStartingDate,
  getContractEndingDate,
  applyStatusFilters,
  applyBasicFilters,
  applyContractNumberFilters,
  isContractEndingSoon,
  applyOrderNumberFilters,
  getContractRenewalDate,
  getNumberMonthsPassed,
  getNumberMonthsLeft,
  groupByObjectKey,
  monthsDiff,
  groupDevicesByProductAndKeyboard,
  isContractOlderOneMonth,
  isContractEnded,
};
