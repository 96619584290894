import { Logger } from "./logger";

class PlaceService {
  constructor() {
    this.initializeServices();
  }

  initializeServices = () => {
    this.places_service = new window.google.maps.places.PlacesService(document.createElement("div"));
    this.autocomplete_service = new window.google.maps.places.AutocompleteService();
  };

  getAddressForPlaceId = async (placeId) => {
    const request = {
      placeId,
      fields: ["ALL"],
    };

    return new Promise((resolve, reject) => {
      this.places_service.getDetails(request, (place, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error("Failed to get postal details"));
          return;
        }
        const zip = place.address_components.find((item) => item.types.includes("postal_code"))?.long_name || "";
        const country = place.address_components.find((item) => item.types.includes("country"))?.long_name || "";
        const countryISO = place.address_components.find((item) => item.types.includes("country"))?.short_name || "";
        const city =
          place.address_components.find((item) => item.types.includes("locality") || item.types.includes("postal_town"))
            ?.long_name || "";

        resolve({
          zip,
          city,
          country,
          countryISO,
        });
      });
    });
  };

  getPlaceIdFromText = async (request, types = ["address"]) => {
    return new Promise((resolve, reject) => {
      this.autocomplete_service.getPlacePredictions({ input: request, types }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else if (
          status === window.google.maps.places.PlacesServiceStatus.INVALID_REQUEST ||
          status === window.google.maps.places.PlacesServiceStatus.REQUEST_DENIED
        ) {
          Logger.info("Google Places API authentication failed. Attempting to reinitialize services.");

          // Attempt to reinitialize the services
          this.initializeServices();
          // Retry the request once
          this.autocomplete_service.getPlacePredictions({ input: request, types }, (retryPredictions, retryStatus) => {
            if (retryStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve(retryPredictions);
            } else {
              const errorMessage = "Google Places API authentication failed. Please refresh the page.";
              Logger.error(errorMessage, { extra: { request } });
              reject(new Error(errorMessage));
            }
          });
        } else {
          const errorMessage = "An error occurred while fetching place predictions.";
          Logger.error(errorMessage, { extra: { request } });
          reject(new Error(errorMessage));
        }
      });
    });
  };
}

export default PlaceService;
