import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";

import MainLoader from "src/components/MainLoader";
import AuthService from "src/components/auth/auth-service";

import User from "src/tools/User";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import useLocalStorage from "src/hooks/useLocalStorage";

const LoginMagic = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const { addToast } = useToastContext();
  const { updateUser } = useUserContext();
  const [, setAccessToken, clearAccessToken] = useLocalStorage("accessToken");

  const validateLoginToken = useCallback(
    async (token) => {
      const service = new AuthService();

      try {
        const response = await service.validateToken({ token });
        const { accessToken, ...userInfo } = response;

        setAccessToken(accessToken);

        const user = new User();

        Object.assign(user, userInfo);
        user.load();
        updateUser(user);

        if (!user.hasRole("ADMIN") && !user.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS")) {
          addToast(
            t(
              "Ce compte n'a pas accès au Cockpit, contactez votre responsable commercial pour la création d'accès supplémentaires.",
            ),
          );
        } else {
          const params = qs.parse(search.slice(1));

          history.push(params.redirect_to || "/");
        }
      } catch (err) {
        console.error(err);
        addToast(t("Votre lien de connexion a expiré. Redemandez un nouveau lien de connexion."));
        history.push("/login");
      }
    },
    [addToast, history, setAccessToken, t, updateUser],
  );

  useEffect(() => {
    clearAccessToken();
    const params = qs.parse(search.slice(1));

    if (params.token) {
      validateLoginToken(params.token);
    }
  }, [search]);

  return <MainLoader />;
};

export default LoginMagic;
