import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useFormValidationContext } from "src/contexts/FormValidationContext";
import User from "src/tools/User";
import AuthService from "src/components/auth/auth-service";
import useLocalStorage from "src/hooks/useLocalStorage";

import { Button, Card, Heading, Layout, Text, TextField } from "tarmac-v3";
import useDeviceType from "src/hooks/useDeviceType";
import LoginWrapper from "./LoginWrapper";

const TokenReset = (props) => {
  const { match } = props;
  const { updateUser } = useUserContext();
  const { addToast } = useToastContext();
  const { setErrors, errors } = useFormValidationContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [, setAccessToken] = useLocalStorage("accessToken");
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";

  const [infos, setInfos] = useState({
    email: "",
    password: "",
    password_repeat: "",
  });
  const [noMatchingPassword, setNoMatchingPassword] = useState(false);

  const service = new AuthService();

  const checkFields = () => {
    const validations = [
      {
        name: "missing_password_1",
        message: t("Veuillez entrer un mot de passe"),
        validator: () => Boolean(infos.password),
      },
      {
        name: "missing_password_2",
        message: t("Veuillez entrer un mot de passe"),
        validator: () => Boolean(infos.password_repeat),
      },
    ];

    const validationErrors = {};

    for (const validation of validations) {
      if (!validation.validator()) {
        validationErrors[validation.name] = validation.message;
      }
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = (event) => {
    setErrors({});
    setNoMatchingPassword(false);
    if (checkFields()) {
      setNoMatchingPassword(false);
      event.preventDefault();
      if (infos.password !== infos.password_repeat) {
        setNoMatchingPassword(true);

        return;
      }

      service
        .validateToken({ userId: match.params.id, password: infos.password, token: match.params.token })
        .then((response) => {
          const { accessToken, ...userInfo } = response;

          setAccessToken(accessToken);

          const user = new User();

          Object.assign(user, userInfo);
          user.load();
          updateUser(user);

          if (!user.hasRole("ADMIN") && !user.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS")) {
            addToast(
              t(
                "Ce compte n'a pas accès au Cockpit, contactez votre responsable commercial pour la création d'accès supplémentaires.",
              ),
            );
          } else {
            history.push("/");
          }
        })
        .catch(() => {
          addToast(t("Ce lien n'est plus valide. Veuillez recommencer la procédure."));
        });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInfos({ ...infos, [name]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <LoginWrapper>
      <Card sx={{ width: isMobile ? "100%" : 450 }} spacing={isMobile ? 2 : 3}>
        <Layout direction="column" spacing={4}>
          <Layout alignSelf="center">
            <Heading variant="h3" align="center">
              {t("Réinitialisation du mot de passe")}
            </Heading>
          </Layout>
          <Text textAlign="center">{t("Bienvenue sur le cockpit. Veuillez choisir un nouveau mot de passe.")}</Text>
          <Layout direction="column" gap={2} alignItems="center" fullWidth>
            <Layout direction="column" gap={1} alignItems="left" fullWidth>
              <TextField
                name="password"
                type="password"
                label={t("Nouveau mot de passe")}
                placeholder={t("Nouveau mot de passe")}
                indication="*"
                fullWidth
                value={infos.password}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              {errors?.missing_password_1 && (
                <Text variant="body2" color="error" align="left">
                  {t(errors.missing_password_1)}
                </Text>
              )}
              {noMatchingPassword && (
                <Text variant="body2" color="error" align="left">
                  {t("Les mots de passe ne correspondent pas")}
                </Text>
              )}
            </Layout>
            <Layout direction="column" gap={1} alignItems="left" fullWidth>
              <TextField
                name="password_repeat"
                type="password"
                label={t("Confirmer nouveau mot de passe")}
                placeholder={t("Confirmer nouveau mot de passe")}
                indication="*"
                fullWidth
                value={infos.password_repeat}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              {errors?.missing_password_2 && (
                <Text variant="body2" color="error" align="left">
                  {t(errors.missing_password_2)}
                </Text>
              )}
              {noMatchingPassword && (
                <Text variant="body2" color="error" align="left">
                  {t("Les mots de passe ne correspondent pas")}
                </Text>
              )}
            </Layout>
          </Layout>
          <Layout>
            <Layout direction="column" spacing={2} alignItems="center" fullWidth>
              <Button label={t("Confirmer")} variant="contained" color="primary" fullWidth onClick={handleSubmit} />
            </Layout>
          </Layout>
        </Layout>
      </Card>
    </LoginWrapper>
  );
};

export default TokenReset;
