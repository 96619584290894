import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

import tagColorsAndNames from "src/data/tagColorsAndNames.json";

import { Button, Card, Drawer, Layout, Text, TextField, useTheme } from "tarmac-v3";
import { useCatalogContext } from "src/contexts/CatalogContext";

const ShoppingListModal = (props) => {
  const { shoppingList, onClose, openModal } = props;

  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();
  const { fetchShoppingLists } = useCatalogContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentShoppingList, setCurrentShoppingList] = useState(null);

  useEffect(() => {
    if (shoppingList) {
      setCurrentShoppingList({ ...shoppingList });
    }
  }, [shoppingList]);

  const handleChange = (event) => {
    setCurrentShoppingList({ ...currentShoppingList, name: event.target.value });
  };

  const handleClick = (tagColor) => {
    setCurrentShoppingList({ ...currentShoppingList, color: tagColor });
  };

  const saveColor = async () => {
    if (currentShoppingList.name.length === 0) {
      addToast(t("La liste d'achat doit obligatoirement avoir un titre "));

      return;
    }

    try {
      if (shoppingList) await currentUser.api.updateShoppingList(shoppingList.id, currentShoppingList);
      fetchShoppingLists();
      onClose();
    } catch (err) {
      addToast(t("La modification de la liste d'achat a échoué"));
    }
  };

  const DrawerActions = useMemo(
    () => (
      <>
        <Button variant="outlined" color="secondary" label={t("Annuler")} fullWidth onClick={onClose} />
        <Button variant="contained" color="darkGreen" label={t("Enregistrer")} fullWidth onClick={saveColor} />
      </>
    ),
    [t, saveColor],
  );

  return (
    <Drawer
      open={openModal}
      onClose={onClose}
      title={t("Modifier la liste d'achat")}
      Actions={DrawerActions}
      anchor="right"
    >
      <Layout direction="column" spacing={1} isScrollable>
        <Layout direction="column" spacing={2} fullWidth>
          <Layout direction="row" spacing={1} justifyContent="space-between">
            <Layout direction="column" spacing={1} fullWidth>
              <Text variant="caption" bold>
                {t("Nom de la liste")}
              </Text>
              <TextField
                onChange={(e) => handleChange(e)}
                value={currentShoppingList ? currentShoppingList?.name : shoppingList?.name}
              />
            </Layout>
          </Layout>
          <Layout direction="column" spacing={2}>
            <Text variant="caption" bold>
              {t("Couleur")}
            </Text>
            {tagColorsAndNames.map((c) => (
              <Card
                onClick={() => handleClick(c.value)}
                selected={
                  currentShoppingList ? c.value === currentShoppingList?.color : c.value === shoppingList?.color
                }
              >
                <Layout spacing={2} direction="row">
                  <Layout
                    direction="row"
                    sx={{
                      width: "24px",
                      borderRadius: "50%",
                      backgroundColor: c.color,
                      border: `0.5px solid ${theme.palette.grey[900]}`,
                    }}
                  />
                  <Layout direction="row">
                    <Text>{t(c.french_name)}</Text>
                  </Layout>
                </Layout>
              </Card>
            ))}
          </Layout>
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default ShoppingListModal;
