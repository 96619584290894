/**
 * Push email to Hubspot for tracking
 * See:
 * - https://community.hubspot.com/t5/APIs-Integrations/Properly-identify-source-of-contacts-added-via-API-currently/m-p/342262
 * - https://legacydocs.hubspot.com/docs/methods/tracking_code_api/identify_visitor
 * @param {string} email
 */
const pushEmailToHubspot = (email) => {
  /* eslint-disable-next-line no-underscore-dangle */
  const hubspotTracking = window._hsq || [];

  hubspotTracking.push([
    "identify",
    {
      email: email.trim(),
    },
  ]);
  hubspotTracking.push([
    "trackEvent",
    {
      id: "User registration",
    },
  ]);
};

export default pushEmailToHubspot;
