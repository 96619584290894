import { LANGUAGE_VARIATIONS } from "src/common/i18n-consts";

const SUPPORTED_COUNTRIES = {
  FR: "france",
  DE: "germany",
  ES: "spain",
  GB: "united kingdom",
  US: "united states",
  OTHER: "other",
};

const getLocalePlaceholder = (country, type) => {
  let countryName = LANGUAGE_VARIATIONS.FR;

  // Most of the time this function get country but in the registration form we can only get a language
  switch (country) {
    case LANGUAGE_VARIATIONS.FR:
      countryName = SUPPORTED_COUNTRIES.FR;
      break;
    case LANGUAGE_VARIATIONS.DE:
      countryName = SUPPORTED_COUNTRIES.DE;
      break;
    case LANGUAGE_VARIATIONS.ES:
      countryName = SUPPORTED_COUNTRIES.ES;
      break;
    case LANGUAGE_VARIATIONS.GB:
      countryName = SUPPORTED_COUNTRIES.GB;
      break;
    case LANGUAGE_VARIATIONS.US:
      countryName = SUPPORTED_COUNTRIES.US;
      break;
    default:
      countryName = country || SUPPORTED_COUNTRIES.FR;
  }

  const placeholdersDictionary = {
    address1: {
      [SUPPORTED_COUNTRIES.FR]: "15 rue de la paix",
      [SUPPORTED_COUNTRIES.DE]: "Pappelallee 78/79",
      [SUPPORTED_COUNTRIES.ES]: "Calle de la Paz 15",
      [SUPPORTED_COUNTRIES.GB]: "483 Green Lanes",
      [SUPPORTED_COUNTRIES.US]: "123 Main Street",
      [SUPPORTED_COUNTRIES.OTHER]: "Rechercher une adresse",
    },
    address2: {
      [SUPPORTED_COUNTRIES.FR]: "3ème étage",
      [SUPPORTED_COUNTRIES.DE]: "3. Etage",
      [SUPPORTED_COUNTRIES.ES]: "3er piso",
      [SUPPORTED_COUNTRIES.GB]: "3rd floor",
      [SUPPORTED_COUNTRIES.US]: "3rd floor",
      [SUPPORTED_COUNTRIES.OTHER]: "Building 3",
    },
    zip: {
      [SUPPORTED_COUNTRIES.FR]: "75009",
      [SUPPORTED_COUNTRIES.DE]: "10437",
      [SUPPORTED_COUNTRIES.ES]: "08001",
      [SUPPORTED_COUNTRIES.GB]: "N13 4BS",
      [SUPPORTED_COUNTRIES.US]: "10001",
      [SUPPORTED_COUNTRIES.OTHER]: "SW1A 1AA",
    },
    city: {
      [SUPPORTED_COUNTRIES.FR]: "Paris",
      [SUPPORTED_COUNTRIES.DE]: "Berlin",
      [SUPPORTED_COUNTRIES.ES]: "Madrid",
      [SUPPORTED_COUNTRIES.GB]: "London",
      [SUPPORTED_COUNTRIES.US]: "New York",
      [SUPPORTED_COUNTRIES.OTHER]: "Bruxelles",
    },
    country: {
      [SUPPORTED_COUNTRIES.FR]: "France",
      [SUPPORTED_COUNTRIES.DE]: "Deutschland",
      [SUPPORTED_COUNTRIES.ES]: "España",
      [SUPPORTED_COUNTRIES.GB]: "United Kingdom",
      [SUPPORTED_COUNTRIES.US]: "United States",
      [SUPPORTED_COUNTRIES.OTHER]: "Belgique",
    },
    firstName: {
      [SUPPORTED_COUNTRIES.FR]: "Jean",
      [SUPPORTED_COUNTRIES.DE]: "Thomas",
      [SUPPORTED_COUNTRIES.ES]: "Maria",
      [SUPPORTED_COUNTRIES.GB]: "Harry",
      [SUPPORTED_COUNTRIES.US]: "John",
    },
    lastName: {
      [SUPPORTED_COUNTRIES.FR]: "Dupont",
      [SUPPORTED_COUNTRIES.DE]: "Müller",
      [SUPPORTED_COUNTRIES.ES]: "García",
      [SUPPORTED_COUNTRIES.GB]: "Kinsley",
      [SUPPORTED_COUNTRIES.US]: "Doe",
    },
    email: {
      [SUPPORTED_COUNTRIES.FR]: "jean@entreprise.com",
      [SUPPORTED_COUNTRIES.DE]: "thomas@enterprise.de",
      [SUPPORTED_COUNTRIES.ES]: "maria@entreprise.es",
      [SUPPORTED_COUNTRIES.GB]: "harry@entreprise.uk",
      [SUPPORTED_COUNTRIES.US]: "john@enterprise.us",
    },
  };

  const fleetCountries = Object.values(SUPPORTED_COUNTRIES);
  const usedCountry = fleetCountries.includes(countryName.toLowerCase())
    ? countryName.toLowerCase()
    : SUPPORTED_COUNTRIES.OTHER;

  return placeholdersDictionary[type][usedCountry];
};

export default getLocalePlaceholder;
